import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Autocomplete from '@mui/material/Autocomplete';
import DatePicker from '@mui/lab/DatePicker';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import InputMask from "react-input-mask";
import States from 'common/states';
import { format } from 'date-fns'
import { styled } from '@mui/material/styles';
import { normalizeValue } from 'utils'

const Input = styled('input')({
    display: 'none',
});

export function Licensing ({licensingList, setLicensingList, licenseDetail, setLicenseDetail, license}) {
    const [requriedFields, setRequriedFields] = useState(null)

    const addNewLicensing = () => {
        var requried = {}

        if (!licenseDetail || !licenseDetail.license_state) {
            requried.license_state = true
        }
        if (!licenseDetail || !licenseDetail.license_number) {
            requried.license_number = true
        }
        if (!licenseDetail || !licenseDetail.licensePDF) {
            requried.licensePDF = true
        }

        if (Object.keys(requried).length === 0) {
            setLicensingList([...licensingList, {
                license_state: licenseDetail.license_state,
                license_number: licenseDetail.license_number,
                license_obtained: licenseDetail.license_obtained ? licenseDetail.license_obtained : getFormatDate(),
                license_expiration: licenseDetail.license_expiration ? licenseDetail.license_expiration : getFormatDate(),
                license_status: 'Y',
                licensePDF: licenseDetail.licensePDF,
            }])
            setLicenseDetail(null)
        }

        setRequriedFields(requried)
    }

    const onChangeTextField = (e) => {
        const { value, name } = e.target
    
        setLicenseDetail(prevState => {
          return {
            ...prevState,
            [name]: value
          }
        })
    }

    const chooseLicensePDF = (e) => {
        if (!e.target.files) {
            return;
        }
        setLicenseDetail(prevState => {
            return {
              ...prevState,
              ["licensePDF"]: e.target.files[0]
            }
        })
    }
    
    const onAutoCompleteChange = (name,newvalue) => {

        setLicenseDetail(prevState => {
            return {
            ...prevState,
            [name]: (newvalue && newvalue.id ? newvalue.id : null)
            }
        });

    };


    const onChangeSelect = (e) => {
        const { value, name } = e.target
        setLicenseDetail(prevState => {
            return {
              ...prevState,
              [name]: value
            }
        })
    };

    const getFormatDate = (date=null) => {
        if (!date)
          date = new Date()
    
        return format(date, 'MM/dd/yyyy')
    }

    const onChangeDate = (value, name) => {
        let newLicenseDetail = {...licenseDetail}
        if (value) {
            var date = new Date(value)
            if(name == 'license_expiration' && date < new Date()) return
            newLicenseDetail[name] = getFormatDate(date)
        } else {
            newLicenseDetail[name] = ""
        }

        setLicenseDetail(newLicenseDetail)
    }

    const removeLicensingPDF = (uindex) => {
        let newlicensingList = licensingList.slice()
        newlicensingList.splice(uindex, 1)
        setLicensingList(newlicensingList)
    }

    const onChangeRadio = (e, name) => {
        const { value } = e.target
        setLicenseDetail(prevState => {
            return {
              ...prevState,
              [name]: value
            }
        })
    };

    return (
        <FormGroup>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className='section-label-with-description'>Licensure</Typography>
                    <Typography className='color-grey section-description'>
                        Please add an active certificate of good standing for each state you wish to be eligible for orders. 
                        Upload a PDF or image of your certificate.
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{p: 2}}>
                <Grid container sx={{marginBottom: '12px', borderBottom: '1px solid black'}} color="primary">
                    <Grid item sm={1}>STATE</Grid>
                    <Grid item sm={2.2}>BAR NUMBER</Grid>
                    <Grid item sm={2.2}>ADMISSION DATE</Grid>
                    <Grid item sm={2.2}>RENEWAL DATE</Grid>
                    <Grid item sm={3}>CERTIFICATE</Grid>
                </Grid>
                {
                    licensingList?.length > 0 ?
                        licensingList.map((item, uindex) => {
                            return <Grid container key={"license"+uindex}>
                                    <Grid item sm={1}>{item.license_state}</Grid>
                                    <Grid item sm={2.2}>{item.license_number}</Grid>
                                    <Grid item sm={2.2}>{item.license_obtained}</Grid>
                                    <Grid item sm={2.2}>{item.license_expiration}</Grid>
                                    <Grid item sm={4} sx={{position: 'relative'}}>
                                        {item.licensePDF && item.licensePDF.name}
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => removeLicensingPDF(uindex)}
                                            sx={{ color: 'red', position: 'absolute', right: '4px', top: '-8px'}}
                                            >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                        })
                    : 
                    <Typography variant="h4" sx={{textAlign: 'center', color: '#ff9800'}}>
                        No licensees
                    </Typography>
                }
            </Box>
            <Grid container spacing={1} className='licenseing-container' >
                <Grid item sm={1.5}>
                    <FormControl fullWidth error={requriedFields?.license_state}>
                        <Autocomplete
                            autoComplete={true}
                            autoSelect={true}
                            id="license_state"
                            name="license_state"
                            options={ Object.keys(States).map( (key) => {
                                return {label : key , id : key, statename:States[key]}
                            }) }
                            renderInput={(params) => <TextField {...params} name="license_state" label="State" error={requriedFields?.license_state}/>}
                            value={ ( licenseDetail && licenseDetail.license_state && licenseDetail.license_state != "" ? licenseDetail.license_state : null )}
                            onChange={(e,newvalue) => {
                                onAutoCompleteChange("license_state",newvalue);
                            }}
                            isOptionEqualToValue={(option,value) => {
                                return (option && option.id === value);
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={2.2}>
                    <TextField
                        fullWidth
                        id="license_number" 
                        name="license_number"
                        label="Bar Number"
                        value={normalizeValue(licenseDetail?.license_number)}
                        onChange={onChangeTextField}
                        error={requriedFields?.license_number}
                    />
                </Grid>
                <Grid item xs={2.2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={ (licenseDetail && licenseDetail.license_obtained && licenseDetail.license_obtained != "" ? licenseDetail.license_obtained : null ) }
                            onChange={(newValue) => onChangeDate(newValue, 'license_obtained')}
                            inputFormat="MM/dd/yyyy"
                            renderInput={(params) => <TextField {...params} label="Admission Date"/>}
                            disableFuture
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2.2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={ ( licenseDetail && licenseDetail.license_expiration && licenseDetail.license_expiration != "" ? licenseDetail.license_expiration : null ) }
                            onChange={(newValue) => onChangeDate(newValue, 'license_expiration')}
                            inputFormat="MM/dd/yyyy"
                            renderInput={(params) => <TextField {...params} label="Renewal Date"/>}
                            disablePast
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item sm={3}>
                    <Button fullWidth component="label" variant="contained" sx={{height: '54px'}} color={requriedFields?.licensePDF && "error"}>
                        <VerticalAlignTopIcon sx={{mr: 1}}/>Select PDF
                        <Input type="file" accept="application/pdf" onChange={chooseLicensePDF}/>
                    </Button>
                    {licenseDetail?.licensePDF && licenseDetail?.licensePDF.name}
                </Grid>
            </Grid>
            <Grid item sm={12} sx={{marginTop: '32px', textAlign: 'right'}}>
                <Button variant="text" onClick={addNewLicensing} className={license && licenseDetail && 'required'}>
                    ADD CERTIFICATE OF GOOD STANDING <LocalHospitalIcon sx={{ml: 2}}/>
                </Button>
            </Grid>
        </FormGroup>
    );
}