import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";

const StyledButton = styled(Button)(css`
    font-size: 16px;
`);

export function Footer ({onClickBack, onClickNext, disabledNext=false, next_caption="Next", next_arrow_icon=true}) {

    return (
        <Box>
            <Grid container>
                <Grid item xs={6}>
                    <StyledButton href="https://www.voxtur.com/" variant="text">
                        Cancel
                    </StyledButton>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    <StyledButton variant="text" sx={{marginRight: '16px'}} onClick={onClickBack}>
                        <ArrowLeftIcon fontSize='large'/>
                        Back
                    </StyledButton>
                    {next_arrow_icon ? 
                        <StyledButton variant="contained" sx={{paddingLeft: '20px', paddingRight: '6px'}} onClick={onClickNext} disabled={disabledNext}>
                            {next_caption}
                            <ArrowRightIcon fontSize='large'/>
                        </StyledButton>
                        :
                        <StyledButton variant="contained" sx={{paddingLeft: '20px', paddingRight: '20px'}} onClick={onClickNext} disabled={disabledNext}>
                            {next_caption}
                        </StyledButton>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}