import { Text, View, StyleSheet } from '@react-pdf/renderer';

const GeneralGuidelinePDFRender = () => {
  return (
    <View style={styles.container}>
        <Text style={styles.title}>VOXTUR NETWORK ATTORNEY AGREEMENT</Text>
        <View style={styles.paragraph}>
          <Text>
            By executing this Voxtur Network Attorney Agreement (“Agreement”), the undersigned Attorney (“Attorney”), for good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, agrees to the terms and conditions set forth herein. This Agreement constitutes a legal agreement between Voxtur Settlement Services, LLC (together with its affiliates, “Voxtur”) and Attorney and governs Attorney’s use of the Licensed Materials (as defined below).
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>1. Scope of Services.</Text>
          <Text style={styles.leftMargin}> 
            Attorney has been engaged by an approved network law firm (“Law Firm”) to provide certain services (“Services”) in connection with the issuance of attorney title opinion letters (each, an “AOL”) using Voxtur’s proprietary software platform (the “Voxtur Platform”). All orders for Services will be placed in the Voxtur Platform, and all Services will be performed and delivered within the Voxtur Platform.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>2. Assignments; Volume of Services.</Text>
          <Text style={styles.leftMargin}> 
            Voxtur’s acceptance of Attorney into the Voxtur Attorney Network and granting of access to the Voxtur Platform does not guarantee that Attorney will receive any orders for Services. Each order for Services placed in the Voxtur Platform shall be assigned on a first-come, first-serve basis.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>3. License and Proprietary Rights.</Text>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - License Grant.
            </Text>
            <Text style={styles.liText}> 
              Subject to the terms of this Agreement, Voxtur hereby grants to Attorney a limited, non-exclusive, non-transferrable license to use the Voxtur Platform, including without limitation, the templates, documents, workflow, review questions, and underlying software contained therein (collectively, the “Licensed Materials”) for the purpose of providing the Services.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Third-Party Components.
            </Text>
            <Text style={styles.liText}> 
              The Licensed Materials may incorporate software and other technology owned by third parties and licensed to Voxtur. Any such third-party software or technology that is incorporated in the Licensed Materials falls under the scope of this Agreement. Such third-party software is licensed, not sold, and will be provided to Attorney pursuant to the terms of this Agreement unless additional or separate license terms apply as indicated.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - License Restrictions. 
            </Text>
            <Text style={styles.liText}> 
              Attorney shall be entitled to use the Voxtur Platform only as contemplated in this Agreement and shall not license, sublicense, sell, resell, lease, transfer, assign, distribute, or otherwise make the Licensed Materials available to any third party. Attorney shall not: (i) modify, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Licensed Materials; (ii) circumvent any user limits or other use restrictions that are built into the Licensed Materials; (iii) access the Licensed Materials in order to build a competitive product or service or copy any ideas, features, or functions of the Licensed Materials.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Lawful Purposes.
            </Text>
            <Text style={styles.liText}> 
              Attorney will at all times comply with all laws, regulations, and policies that may apply to the use of the Voxtur Platform and performance of the Services. Attorney shall use the Licensed Materials for lawful purposes only. Attorney shall not, without limitation: (i) use any type of spider, virus, worm, trojan-horse, time bomb or any other codes or instructions that are designed to distort, delete, damage or disassemble the technology underlying the Voxtur Platform; (ii) send any commercial communication not permitted by applicable law; (iii) endanger any part of any system of Voxtur or any third party; (iv) use the Licensed Materials to store any personal information from individuals who have not consented to Voxtur’s processing of their personal information.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Ownership. 
            </Text>
            <Text style={styles.liText}> 
              Except for the rights and licenses expressly granted in this Agreement, Attorney acknowledges and agrees that all intellectual property rights related to or arising from the Licensed Materials are and shall remain the exclusive property of Voxtur. Nothing in this Agreement shall transfer any such intellectual property rights to, or vest any such intellectual property rights in, Attorney. Attorney shall be entitled to the limited use of the Licensed Materials granted in this Agreement. Attorney shall not take any action to jeopardize, limit, or interfere with Voxtur’s intellectual property rights.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Content.
            </Text>
            <Text style={styles.liText}> 
              Attorney hereby grants Voxtur and its affiliates a license to access and use the content generated by Attorney to facilitate performance of the Services as contemplated by this Agreement. Attorney acknowledges and agrees that Voxtur may obtain and use interaction data from the Voxtur Platform.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Feedback. 
            </Text>
            <Text style={styles.liText}> 
              If Attorney provides Voxtur with any suggestions, comments, or other feedback relating to any aspect of the Licensed Materials (“Feedback”), Voxtur may use such Feedback in the Licensed Materials or in any other Voxtur products or services (collectively, “Voxtur Offerings”). Accordingly, Attorney acknowledges and agrees that: (i) Voxtur is not subject to any confidentiality obligations in respect to the Feedback; (ii) the Feedback is not confidential or proprietary information owned by Attorney or any third party and Attorney has all of the necessary rights to disclose the Feedback to Voxtur; (iii) Voxtur, including its successors and assigns, may freely use, reproduce, publicize, license, distribute, and otherwise commercialize Feedback in any Voxtur Offerings; and (iv) Attorney is not entitled to compensation of any kind from Voxtur in respect of the Feedback.
            </Text>
          </View>      
        </View>  
        <View style={styles.paragraph}>
          <Text style={styles.label}>4. Relationship.</Text>
          <Text style={styles.leftMargin}>
            Attorney acknowledges and agrees that: (i) Attorney has been engaged to provide the Services by the Law Firm, not Voxtur; and (ii) Attorney is not an employee of Voxtur and is not entitled to payment by Voxtur or to any benefits afforded to employees of Voxtur. This Agreement does not create a partnership, franchise, joint venture, or employment relationship between Attorney and Voxtur. Attorney represents and warrants that Attorney is not under any pre-existing obligations inconsistent with this Agreement.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>5. Confidentiality.</Text>
            <View style={styles.li}>
              <Text>
                To facilitate Attorney’s performance of the Services in the Voxtur Platform, Voxtur may be required to disclose or make accessible to Attorney confidential and proprietary information, including, without limitation, the Licensed Materials (collectively, "Confidential Information"). Confidential Information includes, but is not limited to, information and data of any kind concerning any matters affecting or relating to Voxtur, the business or operations of Voxtur (including but not limited to trade secrets), and/or products, drawings, plans, processes, or other data of Voxtur not generally known or available outside of Voxtur. Accordingly, to protect the Confidential Information, Attorney agrees as follows:
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney will hold the Confidential Information in strict confidence and will exercise a reasonable degree of care to prevent disclosure to others.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney will not disclose or divulge, either directly or indirectly, the Confidential Information to others unless first authorized to do so in writing by Voxtur’s Chief Legal Officer.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney will not keep, copy, or reproduce the Confidential Information nor use the Confidential Information commercially or for any purpose other than the performance of Services.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney will, upon request or upon disqualification or removal from the Voxtur Attorney Network, deliver to Voxtur any Confidential Information, including any drawings, notes, documents, equipment, and materials received from Voxtur or originating from the Services.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney hereby acknowledges and agrees that all materials produced or in any way contributed to by Attorney in connection with Attorney’s provision of the Services, including all proofs, drafts, prior versions, derivations thereof, reports, presentations, papers, data, deliverables and all other intellectual property rights (as defined herein) prepared by or with the assistance of Attorney shall be the sole and exclusive property of Voxtur or its affiliates, as applicable, and shall be works made for hire, as defined in the Copyright Act of 1976, 17 U.S.C. §101, as may be amended from time to time (collectively, the “Works Made for Hire”). Attorney shall, upon request by Voxtur and at Voxtur's expense, promptly execute, acknowledge, or deliver any documents or instruments deemed reasonably necessary by Voxtur to document, enforce, protect, or otherwise perfect Voxtur's and its affiliates’ patent and other interests in the Works Made for Hire. Attorney shall assist Voxtur and its affiliates in obtaining, maintaining, and enforcing patents and other proprietary rights in connection with any Works Made for Hire.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney acknowledges and agrees that a breach of this Section 5 (Confidentiality) would result in irreparable harm to Voxtur and its affiliates that could not be adequately or reasonably compensated by monetary damages at law. Therefore, in addition to any other available remedies, Voxtur, its affiliates, and their respective successors and assigns shall be entitled, if any of them so elects, to immediate injunctive relief or other equitable relief to restrain Attorney from any violation of this Agreement, without the necessity of posting a bond or other security. Attorney further acknowledges that the restrictions contained in this Section 5 (Confidentiality) are intended to be, and shall be, for the benefit of and shall be enforceable by, Voxtur’s successors and assigns.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney is hereby notified in accordance with the Defend Trade Secrets Act that Attorney will not be held criminally or civilly liable under any federal or state trade secret law for the disclosure of a trade secret that: (a) is made (i) in confidence to a federal, state, or local government official, either directly or indirectly, or to an attorney; and (ii) solely for the purpose of reporting or investigating a suspected violation of law; or (b) is made in a complaint or other document that is filed under seal in a lawsuit or other proceeding. Attorney is further notified that if Attorney files a lawsuit for retaliation by Voxtur for reporting a suspected violation of law, Attorney may disclose Voxtur’s trade secrets to Attorney’s attorney and use the trade secret information in the court proceeding if Attorney: (x) files any document containing the trade secret under seal; and (y) does not disclose the trade secret, except pursuant to court order.
              </Text>
            </View>
            <View style={styles.li}>
              <Text>
                Attorney acknowledges and agrees that Attorney’s obligations under this Section 5 (Confidentiality) shall continue after Attorney ceases providing the Services.
              </Text>
            </View>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>6 Conflicts of Interest.</Text>
          <Text style={styles.leftMargin}> 
            Attorney shall avoid any situation which involves or may involve a conflict between Attorney’s personal interest and the interest of Voxtur. Attorney shall make prompt and full disclosure to Voxtur of any potential situation which may involve a conflict of interest. Examples of conflicts include:
          </Text>
          <View style={styles.li}>
            <Text>
              Ownership of a significant interest in any outside enterprise which does or seeks to do business with or is a competitor of Voxtur by Attorney or by a member of Attorney’s family.
            </Text>
          </View>
          <View style={styles.li}>
            <Text>
              Serving as a director, officer, partner, consultant, employee, or in a managerial or technical capacity with an outside enterprise which does or is seeking to do business with or is a competitor of Voxtur, including without limitation, any title insurance underwriter, provider, or agent. Exceptions to this may be approved by Voxtur’s Chief Legal Officer.
            </Text>
          </View>
          <View style={styles.li}>
            <Text>
              Acting as a broker, finder, go-between or otherwise for the benefit of a third party in transactions involving or potentially involving Voxtur or its interests.
            </Text>
          </View>
          <View style={styles.li}>
            <Text>
              Any other arrangements or circumstances, including family or other personal relationships, which might dissuade Attorney from acting in the best interest of Voxtur.
            </Text>
          </View>
          <Text style={styles.leftMargin}> 
            The revelation or use of any Confidential Information, data, decisions, plans, or any other information which might be contrary to the interest of Voxtur without prior authorization, is prohibited. The misuse, unauthorized access to, or mishandling of Confidential Information is strictly prohibited and will subject Attorney to immediate removal from the Voxtur Attorney Network.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>7. Security Requirements.</Text>
          <Text style={styles.leftMargin}> 
            Attorney will comply with all technological and information security protocols established by Voxtur related to the Licensed Materials and Attorney’s use of the Voxtur Platform.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>8. Changes to Agreement and Licensed Materials.</Text>
          <Text style={styles.leftMargin}> 
            Voxtur reserves the right to modify this Agreement at any time by providing a revised Agreement to Attorney. The revised Agreement shall become effective within ten (10) calendar days following delivery of the revised Agreement to Attorney, unless Attorney expressly accepts the revised Agreement earlier by indicating such acceptance. Attorney’s express acceptance or continued use of the Licensed Materials after expiry of the 10-day notice period shall constitute acceptance to be bound by the terms and conditions of the revised Agreement. Further, Attorney acknowledges and agrees that Voxtur may alter, update, or upgrade the Licensed Materials from time to time.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>9. Support.</Text>
          <Text style={styles.leftMargin}> 
            Voxtur will provide support for the Voxtur Platform and will use commercially reasonable efforts to make the Voxtur Platform available at all times, with the exception of: (i) planned downtime, or (ii) unavailability caused by circumstances beyond Voxtur’s reasonable control, including without limitation, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems (other than those involving Voxtur employees), or Internet service provider failures or delays. Voxtur will provide upgrades to the Licensed Materials from time to time to provide new features and improvements, bug fixes and error corrections.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>10. Termination. </Text>
          <Text style={styles.leftMargin20}> 
            Without limitation of other remedies, Attorney may be deemed ineligible to provide Services and Voxtur may suspend or terminate Attorney’s access to the Licensed Materials upon the occurrence of any of the following: (i) Attorney’s breach of any term, condition, warranty, covenant or agreement contained in this Agreement; (ii) Attorney’s failure to comply with applicable laws, rules, and regulations in the use of the Licensed Materials or performance of the Services; or (iii) Attorney’s failure to adhere to certain service level commitments set forth by Voxtur or the Law Firm for performance of the Services. Voxtur reserves the right to pursue civil and criminal penalties against Attorney for any breach of Attorney's obligations under this Agreement, including, but not limited to, claims for injunctive relief and monetary damages.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>11. Liability.</Text>
          <View style={{ ...styles.li, marginLeft: 20 }}>
            <Text style={styles.label}> 
              - Indemnification by Attorney.
            </Text>
            <Text style={styles.liText}>
              Attorney agrees to indemnify, defend, and hold Voxtur, its affiliates, subsidiaries and their directors, officers, and employees, harmless from and against any liability and costs, including reasonable attorneys’ fees incurred by such parties, in connection with or arising out of Attorney’s: (i) violation or breach of any material term of this Agreement or any applicable law or regulation, whether or not referenced herein, (ii) violation of any rights of any third-party; or (iii) use or misuse of the Licensed Materials.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Indemnification by Voxtur.
            </Text>
            <Text style={styles.liText}>
              Voxtur agrees to defend Attorney from and against any claim, demand, suit, or proceeding made or brought against Attorney by a third party alleging that the use of the Licensed Materials as permitted hereunder infringes or misappropriates the intellectual property rights of a third-party, and shall indemnify Attorney for any damages finally awarded against, and for reasonable attorney’s fees incurred by, Attorney in connection with any such claim; provided, that Attorney: (i) promptly gives Voxtur written notice of the claim; (ii) gives Voxtur sole control of the defense and settlement of the claim; and (iii) provides to Voxtur reasonable assistance in such defense.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Warranties. 
            </Text>
            <Text style={styles.liText}>
              THE LICENSED MATERIALS ARE PROVIDED “AS IS” WITH NO WARRANTIES AND VOXTUR DOES NOT MAKE ANY EXPRESS, IMPLIED OR STATUTORY WARRANTIES, OR MAKE ANY CLAIMS OR REPRESENTATIONS WITH RESPECT TO THE LICENSED MATERIALS, INCLUDING, WITHOUT LIMITATION, WARRANTIES OR CONDITIONS OF QUALITY, PERFORMANCE, INTEGRITY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR USE FOR A PARTICULAR PURPOSE. VOXTUR FURTHER DOES NOT REPRESENT OR WARRANT THAT THE LICENSED MATERIALS WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE AND ERROR-FREE, NOR DOES VOXTURE WARRANT ANY CONNECTION TO OR TRANSMISSION FROM THE INTERNET.
            </Text>
          </View>
          <View style={styles.li}>
            <Text style={styles.label}> 
              - Limitation of Liability.
            </Text>
            <Text style={styles.liText}>
              EXCEPT FOR THE INDEMNITY OBLIGATIONS SET FORTH IN SECTION 11(B), TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER VOXTUR NOR ANY OF ITS AFFILIATES, SUBSIDIARIES, LICENSORS, OR AGENTS SHALL HAVE ANY LIABILITY TO ATTORNEY OR ANY OTHER PERSON OR ENTITY FOR ANY DAMAGES (WHETHER ARISING FROM THIS AGREEMENT OR RELATED TO THE LICENSED MATERIALS), INCLUDING WITHOUT LIMITATION ANY INDIRECT, RELIANCE, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE OR PROFIT, LOSS OF OR DAMAGE TO DATA, BUSINESS INTERRUPTION, LOSS OF DATA, REPLACEMENT OR RECOVERY COSTS, OR OTHER COMMERCIAL OR ECONOMIC LOSS, WHETHER ARISING FROM CONTRACT, EQUITY, TORT (INCLUDING NEGLIGENCE OR STRICT LIABILITY) OR ANY OTHER THEORY OF LIABILITY), EVEN IF VOXTUR (INCLUDING ITS AFFILIATES, LICENSORS, AND AGENTS) HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE DISCLAIMER OF REPRESENTATIONS, WARRANTIES AND CONDITIONS AND LIMITATION OF LIABILITY HEREIN CONSTITUTE AN ESSENTIAL PART OF THIS AGREEMENT. ATTORNEY ACKNOWLEDGES THAT, BUT FOR THE DISCLAIMER OF REPRESENTATIONS, WARRANTIES AND CONDITIONS AND LIMITATION OF LIABILITY HEREIN, VOXTUR WOULD NOT GRANT THE RIGHTS GRANTED IN THIS AGREEMENT. ATTORNEY’S ONLY RIGHT OR REMEDY WITH RESPECT TO DISSATISFACTION WITH THE LICENSED MATERIALS IS TO IMMEDIATELY CEASE USE OF SUCH LICENSED MATERIALS.
            </Text>
          </View>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>12. Applicable Law.</Text>
          <Text style={styles.leftMargin20}> 
            This Agreement shall be governed by and construed in accordance with the laws of the state of Florida, without giving effect to any conflict of laws provisions. Any action, suit, or other proceeding arising under or relating to this Agreement shall be brought in a court of competent jurisdiction in Duval County, in the State of Florida, and the parties hereby consent to the sole jurisdiction of the state and federal courts sitting in the State of Florida.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>13. Assignment.</Text>
          <Text style={styles.leftMargin20}> 
            This Agreement shall not be assigned by Attorney, whether voluntarily or involuntarily or by operation of law, in whole or in part, to any other entity without the prior written consent of Voxtur.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>14. Entire Agreement.</Text>
          <Text style={styles.leftMargin20}> 
            The terms and conditions of this Agreement constitute the entire agreement between Attorney and Voxtur with respect to the subject matter hereof and will supersede and replace all prior understandings and agreements, in whatever form, regarding the subject matter
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>15. Notices.</Text>
          <Text style={styles.leftMargin20}> 
            All notices required to be provided hereunder shall be made in writing and may be delivered personally, via email, or certified mail, return receipt requested, to the addresses specified by the parties
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>16. Severability. </Text>
          <Text style={styles.leftMargin20}> 
            The provisions of this Agreement will be applicable only to the extent that they do not violate applicable law and are intended to be limited to the extent necessary so that they will not render this Agreement invalid, illegal, or unenforceable. If any provision of this Agreement or any application thereof is held to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of other provisions of this Agreement or of any other application of such provision will in no way be affected thereby.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>17. Waiver.</Text>
          <Text style={styles.leftMargin20}> 
            The delay or failure of either party at any time to require performance of any provisions hereof shall in no manner affect the rights at a later time to enforce the same. No waiver by either party of any condition or of the breach of any term contained in this Agreement, whether by conduct, or otherwise, in any one or more instances, shall be deemed to be, or considered as, a further or continuing waiver of any such condition or of the breach of such term or any other term of this Agreement.
          </Text>
        </View>
    </View>
  );
}

export default GeneralGuidelinePDFRender;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: 14,
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  paragraph: {
    paddingVertical: 10,
  },
  label: {
    fontSize: 11,
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  li: {
    paddingHorizontal: 12,
    paddingVertical: 4
  },
  liText: {
    paddingLeft: 8
  },
  leftMargin: {
    marginLeft: 12,
  },
  leftMargin20: {
    marginLeft: 20,
  },
});