import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SignatureCanvas from 'react-signature-canvas'
import { Footer } from "./footer";
import { cl } from '@twipped/utils';
import { Document } from 'core/models'
  
export function ApplicationCertification ({nextRegisterStep, setSignature}) {
    const sigCanvas = useRef({})
    const [sigRequried, setSigRequried] = useState(false)

    const clearSignature = () => {
        sigCanvas.current.clear();
    }

    const onClickBack = () => {
        nextRegisterStep(-1)
    }

    const onClickNext = () => {
        sigCanvas.current.getTrimmedCanvas().toBlob(async (blob) => {
            if (blob.size < 1000) {
              setSigRequried(true)
              return
            }
            const file = new File([blob], "signature.png");
            await Document.uploadSignature(file)
            setSignature(file)
            nextRegisterStep()
        });
    }

    return (
        <Box>
            <Box className='step-title'>
                APPLICATION CERTIFICATON
            </Box>
            <Box className='step-container'>
                <Box sx={{fontWeight: 'bold', marginBottom: '24px'}}>
                    Certification
                </Box>
                <Box>
                    I certify that the information provided in this Voxtur Attorney Network Application accurate.
                    I also understand that failure to report completely and accurately may negatively impact order assignment. &nbsp;
                    <Typography variant="span" className='font-weight-bold'>Please use your mouse to sign in the box below.</Typography>
                </Box>
                <Typography component="div" className='sig-container'>
                    <Typography
                        component="div"
                        className={cl(
                            'contents',
                            sigRequried && 'sig-requried'
                        )}
                    >
                        <SignatureCanvas 
                            penColor='black'
                            canvasProps={{ className: 'sig-canvas' }}
                            ref={sigCanvas}
                            clearOnResize={true}
                        />                
                        <Button onClick={clearSignature} className="clear-button">Clear</Button>
                    </Typography>
                </Typography>
                <Footer onClickBack={onClickBack} onClickNext={onClickNext} next_caption="SUBMIT APPLICATION" next_arrow_icon={false}/>
            </Box>
        </Box>
    );
}