import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Footer } from "./footer";

const scroll = {
    "&::-webkit-scrollbar": {
      width: "7px",
      height: "80px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: " 5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#1976D2",
    },
};

const fontStyle = {
    "&.MuiTypography-root": {
      fontSize: "0.875rem",
      fontFamily: "Inter",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      height: "30vh",
    },
};

export function NetworkAttorneyAgreement ({nextRegisterStep, guideline}) {
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [readTerms, setReadTerms] = useState(true);

    useEffect(async () => {
        if (guideline?.accepteddate)
            setAcceptTerms(true);
    }, [])

    const onClickBack = () => {
        nextRegisterStep(-1)
    }
    const onClickNext = () => {
        nextRegisterStep()
    }

    const handleChange = (event) => {
        if (event.target.checked) {
            guideline.accepteddate = new Date()
        }
        setAcceptTerms(event.target.checked);
    };
    const handleScrollReadTermsCondition = () => {
        const termsCondition = document.getElementById("termsCondition");
        var scrollY = termsCondition.scrollTop;
        var scrollBottom =
          termsCondition.scrollHeight - termsCondition.clientHeight - 1;
        if (scrollY > scrollBottom || scrollY === scrollBottom) {
          setReadTerms(false);
        }
    };

    return (
        <Box>
            <Box className='step-title'>
                NETWORK ATTORNEY AGREEMENT
            </Box>
            <Box className='step-container'>
                <Box className='font-weight-bold'>Voxtur Network Attorney Agreement</Box>
                <Box sx={{ px: 5 }}>
                    <List sx={{ width: "100%", bgcolor: "background.paper", pt: 2 }}>
                        <div>
                        <ListItem
                            alignItems="flex-start"
                            sx={{
                            display: "flex",
                            px: 0,
                            py: 0.5,
                            }}
                        >
                            <Typography
                            variant="body2"
                            display="block"
                            gutterBottom
                            id="termsCondition"
                            onScroll={handleScrollReadTermsCondition}
                            sx={{
                                ...fontStyle,
                                overflowY: "scroll",
                                width: "100%",
                                py: 0.5,
                                pr: 1,
                                ...scroll,
                            }}
                            >
                            <span
                                dangerouslySetInnerHTML={{
                                __html: guideline?.guidelinetext !=="" ? guideline?.guidelinetext : '',
                                }}
                            />
                            </Typography>
                        </ListItem>
                        <Divider component="li" />
                        </div>
                    </List>
                </Box>
                <Box
                    sx={{
                        px: 5,
                        mb: 3,
                        width: "90%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <FormControlLabel
                        sx={{
                        "&.MuiFormControlLabel-label": {
                            fontSize: "0.875rem !important",
                        },
                        "&.MuiTypography-root": { fontSize: "0.875rem !important" },
                        }}
                        control={
                            <Checkbox
                                checked={acceptTerms}
                                disabled={readTerms}
                                onChange={handleChange}
                                name="acceptTerms"
                                sx={{ my: 2 }}
                            />
                        }
                        label="I have read and agree to the Voxtur Network Attorney Agreement"
                    />
                </Box>
                <Footer onClickBack={onClickBack} onClickNext={onClickNext} disabledNext={!acceptTerms}/>
            </Box>
        </Box>
    );
}