import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";
import { format } from 'date-fns'

const Header = styled(Grid)(css`
    background-color: white;
    padding: 3% 8%;
    font-size: 14px
`);

const Container = styled('div')(css`
    background-color: #F3F3F3;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 16px;
    padding-bottom: 48px;
`);

const WelcomeContent = styled('div')(css`
    line-height: 2;
`);

const Content = styled('div')(css`
    line-height: 2;
    margin-top: 24px;
    margin-bottom: 24px;
`);

const ButtonBox = styled(Box)(css`
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
`);

const InvalidContent = styled('div')(css`
    line-height: 2;
    margin-bottom: 24px;
    padding: 10% 5%;
    background: white;
    min-height: 30vh;
    text-align: center;
`);

export function InvitedPage ({ nextRegisterStep, branding, firmDetails }) {
    const onClickStarted = () => {
        nextRegisterStep("LandingPage");
    }

    return (
        <Box>
            {firmDetails && firmDetails.employee && Object.keys(firmDetails.employee).length > 0 &&
                <Header container>
                    <Grid item xs={12}>
                        Voxtur Vendor Management Team
                    </Grid>
                    <Grid item xs={6}>
                        To: {firmDetails.employee.firstname} {firmDetails.employee.lastname}({firmDetails.employee.email})
                    </Grid>
                    <Grid item xs={6}n sx={{textAlign: 'right'}}>
                        {firmDetails.employee.register_date && format(new Date(firmDetails.employee.register_date), 'E M/dd/yyyy h:mm b')}
                    </Grid>
                </Header>
            }
            <Container>
                {branding?.logoPrimary &&
                    <Box
                        sx={{
                            flexGrow: 1,
                            marginBottom: '16px',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(/common/companyfiles.php?logo=1&filename=${branding.logoPrimary})`,
                            height: 54,
                        }}
                    />
                }
                <Box className='step-title text-center'>
                    YOU’RE INVITED
                </Box>
                {firmDetails && <>
                    {(firmDetails.error || !firmDetails.employee || Object.keys(firmDetails.employee).length == 0)?
                        <InvalidContent>
                            Your request is invalid, please feel free to reach us at VoxturAOL@vss.voxtur.com
                        </InvalidContent>
                        :
                        <Box className='step-container font-small' sx={{background: 'white'}}>
                            <WelcomeContent>
                                Hello {firmDetails.employee.firstname},
                            </WelcomeContent>
                            <WelcomeContent>
                                {firmDetails.company_name} Firm has joined the Voxtur Attorney Network and has invited you to complete the application process to open your account.
                            </WelcomeContent>
                            <Content>
                                The process should only take a few minutes, and is required to be eligible for orders. 
                            </Content>
                            <Content>
                                If you have any questions, please feel free to reach us at VoxturAOL@vss.voxtur.com
                            </Content>
                            <ButtonBox>
                                <Button className='next-step' variant="contained" onClick={onClickStarted}>
                                    CLICK HERE TO COMPLETE THE APPLICATION
                                </Button>
                            </ButtonBox>
                        </Box>
                    }
                </>
                }
            </Container>
        </Box>
    );
}