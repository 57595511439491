import React from 'react';
import { PDFDocument, PDFPage, PDFView, PDFText, PDFImage, PDFLabeleedText} from 'components/pdf-document';
import { StyleSheet } from '@react-pdf/renderer';
import GeneralGuidelinePDFRender from './GeneralGuidelinePDFRender';
import SocialMediaGuidelinePDFRender from './SocialMediaGuidelinePDFRender';

// A4 size 595 x 842 in 72 DPI
// Create styles
const styles = StyleSheet.create({
  logo: { paddingLeft: 400 },
  header: { paddingLeft: 400 },
  thead: { padding: '4px 7px' },
  tcell: { padding: '4px 7px 6px 7px' },
  firm_logo: { padding: '20px 10px', marginRight: '32px' },
  signature: { padding: '32px' },
  checkmark: {marginTop: '4px', marginBottom: '4px'},
  checkbox: {width: "14px", height: "14px", marginTop: '3px', marginRight: '6px'}
});

// Create Document Component
export const SubmissionDocument = ({ branding, data }) => {

  const logo = () => (
    <PDFView styles={styles.logo}>
      <PDFImage value={`/common/companyfiles.php?logo=1&filename=${branding.logoPrimary}`} width="150px"/>
    </PDFView>
  )

  const checkmark = (text) => (
    <PDFView className="flex" styles={styles.checkmark}>
      <PDFView>
        <PDFImage
          styles={styles.checkbox}
          value={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAXVBMVEVQr1BMsE9Mr1BNr1BMr1BNr1BMr1BQsVRsvW9TslfO58/6+vp9xIDo8+hetmLN5s7p8upou2tuvnHv9e+m1qhlumj2+Pap16uBxoSs2K5/xYL1+PVjuWZZtVz///8fVGVyAAAABnRSTlMQpPGjovAsv4LQAAAAAWJLR0QecgogKwAAAAd0SU1FB+YKAwM7BoNbESQAAABxSURBVCjPvZLHDoAwDENNW8oIe5T9/78JB1SICEfw0S+yozZAoOxDSgOBFWWgZBDCvuhLEMUySFLKJHD4eSGApKSqZlFN253zPS9vHA3M91Gjo+nuXx3zQj6fl4/uNs+2Wre/HtGD16/VMjCAkc7HYAeVcA8wf/5KAAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0wM1QwMzo1OTowNiswMDowMD07/roAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTAtMDNUMDM6NTk6MDYrMDA6MDBMZkYGAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEwLTAzVDAzOjU5OjA2KzAwOjAwG3Nn2QAAAABJRU5ErkJggg=="}
          />
      </PDFView>
      <PDFView>
        <PDFText value={text}/>         
      </PDFView>
    </PDFView>
  )

  const section_header = (value) => (
    <PDFText
      className="bold secondary fs-16 mb-9 mt-20"
      value={value}
    />
  )

  let total_license_employee_rows = data.certificates.length;
  if (data.employees) total_license_employee_rows += data.employees.length;
  const sig_is_nextpage = data.w9form && total_license_employee_rows < 5;

  return (
    <PDFDocument>
      <PDFPage>
        {logo()}
        {data.compname &&
          <PDFView className="flex">
            {data.firm_logo && 
              <PDFView className="w-30" styles={styles.firm_logo}>
                <PDFImage value={URL.createObjectURL(data.firm_logo)} />
              </PDFView>
            }
            <PDFView className="w-50">
              {section_header(data.compname)}
              <PDFText value={`${data.compaddress1} ${data.compaddress2}`}/>
              <PDFText value={`${data.compcity} ${data.compstate} ${data.compzipcode}`}/>
              <PDFText value={`${data.office_phone} ${data?.office_phoneext}`}/>
            </PDFView>
        </PDFView>
        }
        <PDFView className="flex">
          <PDFView className="w-40">
            {section_header("Contact")}
            <PDFText value={`${data.firstname} ${data.lastname}`}/>
            <PDFText value={`${data.job_title} ${data.vendor}`}/>
            <PDFText value={data.email}/>
            <PDFText value={`${data.phone} ${data?.phoneext}`}/>
            <PDFText value={data.cell_phone}/>
          </PDFView>
          <PDFView className="w-60">
            {section_header("Malpractice Insurance Coverage")}
            <PDFLabeleedText label="Provider" value={data.eo_ins_company} labelWidth='w-50' valueWidth='w-50'/>
            <PDFLabeleedText label="Expiration" value={data.eo_expires} labelWidth='w-50' valueWidth='w-50'/>
            <PDFLabeleedText label="Amount (each)" value={data.eo_amount_each} labelWidth='w-50' valueWidth='w-50'/>
            <PDFLabeleedText label="Amount (aggregate)" value={data.eo_amount_agg} labelWidth='w-50' valueWidth='w-50'/>
            <PDFLabeleedText label="Malpractice Document" value={data.malpractice_document ? data.malpractice_document.name : ''} labelWidth='w-50' valueWidth='w-50'/>
          </PDFView>
        </PDFView>
        <PDFView className="flex mt-20">
          <PDFView className="w-80">
            <PDFText className="black bold" value="Years of Experience Practicing in Real Estate or Related Field of Law:"/>
          </PDFView>
          <PDFView className="w-10">
            <PDFText value={data.experience_years}/>
          </PDFView>
        </PDFView>
        <PDFView className="flex mt-10">
          <PDFView className="w-80">
            <PDFText className="black bold" value="Are you aware of any fact, circumstance, incident, or situation that may result in a malpractice claim being made against you?"/>
          </PDFView>
          {data.malpractice_claim &&
          <PDFView className="w-10">
            <PDFText value={data.malpractice_claim == "Y" ? "Yes" : "No"}/>
          </PDFView>
          }
        </PDFView>
        {data.malpractice_claim_explain &&
          <PDFView className="border p-5-10">
            <PDFText value={data.malpractice_claim_explain}/>
          </PDFView>
        }
        <PDFView className="flex mt-10">
          <PDFView className="w-80">
            <PDFText className="black bold" value="Have you ever been the subject of a reprimand, disciplinary action, or criminal action by any federal, state, or local authority, professional association, or state licensing board?"/>
          </PDFView>
          {data.criminal_action &&
          <PDFView className="w-10">
            <PDFText value={data.criminal_action == "Y" ? "Yes" : "No"}/>
          </PDFView>
          }
        </PDFView>
        {data.criminal_action_explain &&
          <PDFView className="border p-5-10">
            <PDFText value={data.criminal_action_explain}/>
          </PDFView>
        }
        <PDFView className="flex mt-20">
          <PDFView className="w-80">
            <PDFText className="black bold" value="Are you aware of any actual, potential, or perceived conflict of interest that may exist as a result of your providing services to Voxtur?"/>
          </PDFView>
          {data.perceived_conflict &&
          <PDFView className="w-10">
            <PDFText value={data.perceived_conflict == "Y" ? "Yes" : "No"}/>
          </PDFView>
          }
        </PDFView>
        {data.perceived_conflict_explain &&
          <PDFView className="border p-5-10">
            <PDFText value={data.perceived_conflict_explain}/>
          </PDFView>
        }
        <PDFView className="flex mt-30">
          <PDFView className="w-30" styles={{paddingTop: 4}}>
            <PDFText className="black bold" value="Contact Preferences:"/>
          </PDFView>
          <PDFView className="w-20">
            {data.contact_pref_email == "Y" && checkmark("Email")}
          </PDFView>
          <PDFView className="w-30">
            {data.contact_pref_text == "Y" && checkmark("Text Message")}
          </PDFView>
        </PDFView>
        {checkmark("I have read and agree to the Voxtur Network Attorney Agreement")}
        {checkmark("I have read and agree to the Voxtur Social Media Policy")}
      </PDFPage>
      <PDFPage>
        {logo()}
        <PDFView>
          {section_header("Licensure")}
          <PDFView className="row flex bg-dark">
            <PDFView className="w-10" styles={styles.thead}>
              <PDFText className="white bold" value="State"/>
            </PDFView>
            <PDFView className="w-13" styles={styles.thead}>
              <PDFText className="white bold" value="Bar No."/>
            </PDFView>
            <PDFView className="w-17" styles={styles.thead}>
              <PDFText className="white bold" value="Admission Date"/>
            </PDFView>
            <PDFView className="w-17" styles={styles.thead}>
              <PDFText className="white bold" value="Renewal Date"/>
            </PDFView>
            <PDFView className="w-13" styles={styles.thead}>
              <PDFText className="white bold" value="Status"/>
            </PDFView>
            <PDFView className="w-30" styles={styles.thead}>
              <PDFText className="white bold" value="Certificate"/>
            </PDFView>
          </PDFView>
          {data.certificates && data.certificates.map((cert, i) => {
            return (
              <PDFView key={i} className="row flex">
                <PDFView className="w-10" styles={styles.tcell}>
                  <PDFText className="dark" value={cert.license_state}/>
                </PDFView>
                <PDFView className="w-13" styles={styles.tcell}>
                  <PDFText className="dark" value={cert.license_number}/>
                </PDFView>
                <PDFView className="w-17" styles={styles.tcell}>
                  <PDFText className="dark" value={cert.license_obtained}/>
                </PDFView>
                <PDFView className="w-17" styles={styles.tcell}>
                  <PDFText className="dark" value={cert.license_expiration}/>
                </PDFView>
                <PDFView className="w-13" styles={styles.tcell}>
                  <PDFText className="dark" value={cert.license_status == "Y" ? "Active" : "Inactive"}/>
                </PDFView>
                <PDFView className="w-30" styles={styles.tcell}>
                  <PDFText className="dark" value={cert.licensePDF ? cert.licensePDF.name : ''}/>
                </PDFView>
              </PDFView>
            )
          })}
        </PDFView>
        {data.employees &&
          <PDFView>
            {section_header("Invited Attorneys")}
            <PDFView className="row flex bg-dark">
              <PDFView className="w-20" styles={styles.thead}>
                <PDFText className="white bold" value="First Name"/>
              </PDFView>
              <PDFView className="w-20" styles={styles.thead}>
                <PDFText className="white bold" value="Last Name"/>
              </PDFView>
              <PDFView className="w-25" styles={styles.thead}>
                <PDFText className="white bold" value="Cell Phone"/>
              </PDFView>
              <PDFView className="w-35" styles={styles.thead}>
                <PDFText className="white bold" value="Email"/>
              </PDFView>
            </PDFView>
            {data.employees.map((employee, i) => {
              return (
                <PDFView key={i} className="row flex">
                  <PDFView className="w-20" styles={styles.tcell}>
                    <PDFText className="dark" value={employee.firstname}/>
                  </PDFView>
                  <PDFView className="w-20" styles={styles.tcell}>
                    <PDFText className="dark" value={employee.lastname}/>
                  </PDFView>
                  <PDFView className="w-25" styles={styles.tcell}>
                    <PDFText className="dark" value={employee.phone}/>
                  </PDFView>
                  <PDFView className="w-35" styles={styles.tcell}>
                    <PDFText className="dark" value={employee.email}/>
                  </PDFView>
                </PDFView>
              )
            })}
          </PDFView>
        }
        {data.w9form &&
          <PDFView>
            {section_header("Payment Information")}
            <PDFLabeleedText label="Name (as shown on your income tax return)" value={data.w9form.tax_fullname} border={true}/>
            <PDFLabeleedText label="Business Name/disregard entity name" value={data.w9form.tax_company} border={true}/>
            <PDFLabeleedText label="Checkbox for federal tax classification of the person" value={data.w9form.tax_entity_type} border={true}/>
            <PDFLabeleedText label="Limited liability company. the tax classification" value={data.w9form.tax_llc_type} border={true}/>
            <PDFLabeleedText label="Exempt payee code" value={data.w9form.tax_exempt_payeecode} border={true}/>
            <PDFLabeleedText label="Exemption from FATCA reporting code" value={data.w9form.tax_exempt_fatcacode} border={true}/>
            <PDFLabeleedText label="Address (number, street, and apt. or suite no.)" value={data.w9form.tax_address} border={true}/>
            <PDFLabeleedText label="City, state, and ZIP Code" value={`${data.w9form.tax_city}, ${data.w9form.tax_state} ${data.w9form.tax_zipcode}`} border={true}/>
            <PDFLabeleedText label="Account number(s)" value={data.w9form.account_number} border={true}/>
            {data.w9form.tax_type == "ssn" ?
              <PDFLabeleedText label="Social Security Number" value={data.w9form.tax_ssn} border={true}/>
              :
              <PDFLabeleedText label="Employer Identification Number" value={data.w9form.tax_ein} border={true}/>
            }
            <PDFLabeleedText label="Signature of U.S. Person" value={data.w9form.tax_signature_usperson} border={true}/>
            <PDFLabeleedText label="Signature Date" value={data.w9form.tax_signature_dts} border={true}/>
          </PDFView>
        }
        {data.signature && !sig_is_nextpage &&
          <PDFView>
            {section_header("Application Certification")}
            <PDFView styles={styles.signature}>
              <PDFImage value={URL.createObjectURL(data.signature)} />
            </PDFView>
          </PDFView>
        }
      </PDFPage>
      {data.signature && sig_is_nextpage &&
        <PDFPage>
          <PDFView>
            {section_header("Application Certification")}
            <PDFView styles={styles.signature}>
              <PDFImage value={URL.createObjectURL(data.signature)} />
            </PDFView>
          </PDFView>
        </PDFPage>
      }
      <PDFPage>
        <GeneralGuidelinePDFRender />
      </PDFPage>
      <PDFPage>
        <SocialMediaGuidelinePDFRender />
      </PDFPage>
    </PDFDocument>
  )
}