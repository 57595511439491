import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Switch from '@mui/material/Switch';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import InputMask from "react-input-mask";
import { normalizeValue } from 'utils'
import { format } from 'date-fns'
import { cl } from '@twipped/utils';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";

const StyledTextField = styled(TextField)(css`
    input {
        height: 28px;
    }
`);

export function W9Document ({ updateW9Details, requriedFields, w9Form, vendor }) {
    const [w9Detail, setW9Detail] = useState(null)

    React.useEffect(() => {
        if (w9Form) {
            setW9Detail(w9Form)
        }
    }, []);

    React.useEffect(() => {
        updateW9Details(w9Detail)
    }, [w9Detail]);

    const onChangeTextField = (e) => {
        const { value, name } = e.target
    
        setW9Detail(prevState => {
          return {
            ...prevState,
            [name]: value
          }
        })
    }

    const onChangeTaxEntityType = (e) => {
        const { checked, name, value } = e.target
        
        setW9Detail(prevState => {
            return {
              ...prevState,
              ["tax_entity_type"]: value
            }
        })
    };

    const onChangeCheckBox = (e) => {
        const { checked, name } = e.target
        setW9Detail(prevState => {
            return {
              ...prevState,
              [name]: checked
            }
        })
    };

    const onChangeSelect = (e) => {
        const { value, name } = e.target
        setW9Detail(prevState => {
            return {
              ...prevState,
              [name]: value
            }
          })
    };

    const getFormatDate = (date=null) => {
        if (!date)
          date = new Date()
    
        return format(date, 'MM/dd/yyyy')
    }

    const onChangeSignDate = (value) => {
        let newW9Detail = {...w9Detail}
        if (value) {
            var date = new Date(value)
            if(date < new Date()) return
            newW9Detail['tax_signature_dts'] = getFormatDate(date)
        } else {
            newW9Detail['tax_signature_dts'] = ""
        }

        setW9Detail(newW9Detail)
    }

    const changeTaxType = (e) => {
        const value = e.target.checked ? "ein" : "ssn"
        setW9Detail(prevState => {
            return {
              ...prevState,
              ["tax_type"]: value
            }
        })
    }

    const handleW9Download = async(filename) => {
        const result = await vendor.updateVendorProfile(w9Detail)
        vendor.downloadVendorW9().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
        })
      }

    return (
        <FormGroup>
            <Grid container className='wform-header'>
                <Grid item xs={12} sm={2}>
                    <Box sx={{display:'flex', alignItems: 'flex-end'}}>
                        <Typography>Form</Typography>
                        <Typography variant="h2" sx={{marginLeft: '3px'}}>W-9</Typography>
                    </Box>
                    <Typography>(Rev. October 2018)</Typography>
                    <Typography>Department of the Treasury Internal Revenue Service</Typography>
                </Grid>
                <Grid item xs={12} sm={10} className="text-center" sx={{paddingTop: "3% !important", borderLeft: '2px solid black'}}>
                    <Typography variant='h4'>Request for Taxpayer</Typography>
                    <Typography variant='h4'>Identification Number and Certification</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="wform-component">
                    <Typography>1. Name (as shown on your income tax return). Name is required on this line:</Typography>
                    <TextField
                        fullWidth
                        id="tax_fullname"
                        name="tax_fullname"
                        label="This is the name we will file the 1099 under"
                        value={normalizeValue(w9Detail?.tax_fullname)}
                        onChange={onChangeTextField}
                        error={requriedFields?.tax_fullname}
                    />
                </Grid>
                <Grid item xs={12} className="wform-component">
                    <Typography>2. Business Name/disregard entity name, if different from above</Typography>
                    <TextField
                        fullWidth
                        id="tax_company"
                        name="tax_company"
                        label="This is who the check will be made out to, if filled (not required)"
                        value={normalizeValue(w9Detail?.tax_company)}
                        onChange={onChangeTextField}
                        error={requriedFields?.tax_company}
                    />
                </Grid>
                <Grid item xs={12} sm={8} className={cl('wform-component', 'left-pane')}>
                    3. Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes.
                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            <FormControlLabel
                                sx={{fontSize: '12px'}}
                                control={
                                    requriedFields?.tax_entity_type ? (
                                        <Checkbox id="tax_entity_type_ind" name="tax_entity_type_ind" value="IND" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Checkbox id="tax_entity_type_ind" name="tax_entity_type_ind" value="IND"/>
                                    )
                                }
                                label="Individual/sole proprietor or single-member LLC"
                                checked={w9Detail?.tax_entity_type == "IND"}
                                onChange={onChangeTaxEntityType}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <FormControlLabel
                                control={
                                    requriedFields?.tax_entity_type ? (
                                        <Checkbox id="tax_entity_type_corp" name="tax_entity_type_corp" value="CORP" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Checkbox id="tax_entity_type_corp" name="tax_entity_type_corp" value="CORP"/>
                                    )
                                }
                                label="C Corporation"
                                checked={w9Detail?.tax_entity_type == "CORP"}
                                onChange={onChangeTaxEntityType}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <FormControlLabel
                                control={
                                    requriedFields?.tax_entity_type ? (
                                        <Checkbox id="tax_entity_type_scorp" name="tax_entity_type_scorp" value="SCORP" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Checkbox id="tax_entity_type_scorp" name="tax_entity_type_scorp" value="SCORP" />
                                    )
                                }
                                label="S Corporation"
                                checked={w9Detail?.tax_entity_type == "SCORP"}
                                onChange={onChangeTaxEntityType}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item sm={8}>
                            <FormControlLabel
                                control={
                                    requriedFields?.tax_entity_type ? (
                                        <Checkbox id="tax_entity_type_llc" name="tax_entity_type_llc" value="LLC" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Checkbox id="tax_entity_type_llc" name="tax_entity_type_llc" value="LLC" />
                                    )
                                }
                                label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
                                checked={w9Detail?.tax_entity_type == "LLC"}
                                onChange={onChangeTaxEntityType}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <FormControl fullWidth error={requriedFields?.tax_llc_type}>
                                <Select
                                    id="tax_llc_type"
                                    name="tax_llc_type"
                                    value={normalizeValue(w9Detail?.tax_llc_type)}
                                    defaultValue=""
                                    onChange={onChangeSelect}
                                    size="small"
                                    disabled={ !(w9Detail?.tax_entity_type == "LLC") }
                                >
                                    <MenuItem value="corp">Corporation</MenuItem>
                                    <MenuItem value="scorp">S Corporation</MenuItem>
                                    <MenuItem value="partner">Partnership</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography sx={{paddingLeft: '32px', paddingRight: '40px'}}>
                                Note: Check the appropriate box in the line above for the tax classification of the single-member owner.
                                Do not check LLC if the LLC is classified as a single-member LLC that is disregarded from the owner
                                unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes.
                                Otherwise, a single-member LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.   
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Checkbox
                                id="tax_other_checkbox"
                                name="tax_other_checkbox"
                                checked={w9Detail?.tax_entity_type == "OTHER"}
                                onChange={onChangeTaxEntityType}
                                value="OTHER"
                                sx={{paddingLeft: 0}}/>
                            <TextField
                                id="tax_other_descrip"
                                name="tax_other_descrip"
                                label="Other"
                                value={normalizeValue(w9Detail?.tax_other_descrip)}
                                onChange={onChangeTextField}
                                error={requriedFields?.tax_other_descrip}
                                disabled={ !(w9Detail?.tax_entity_type == "OTHER") }
                                sx={{width: 'calc(100% - 34px)'}}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className="wform-component right-pane">
                    4. Exemptions (codes apply only to certain entities, not individuals
                    <Typography sx={{mt: 1}}>Exempt payee code (if any)</Typography>
                    <TextField
                        fullWidth
                        id="tax_exempt_payeecode"
                        name="tax_exempt_payeecode"
                        value={normalizeValue(w9Detail?.tax_exempt_payeecode)}
                        onChange={onChangeTextField}
                        error={requriedFields?.tax_exempt_payeecode}
                    />
                    <Typography sx={{mt: 1}}>Exemption from FATCA reporting code (if any)</Typography>
                    <TextField
                        fullWidth
                        id="tax_exempt_fatcacode"
                        name="tax_exempt_fatcacode"
                        value={normalizeValue(w9Detail?.tax_exempt_fatcacode)}
                        onChange={onChangeTextField}
                        error={requriedFields?.tax_exempt_fatcacode}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box className="wform-component left-pane">
                        <Typography>
                            5. Address (number, street, and apt. or suite no.)
                        </Typography>
                        <TextField
                            fullWidth
                            id="tax_address"
                            name="tax_address"
                            label="Street Address"
                            value={normalizeValue(w9Detail?.tax_address)}
                            onChange={onChangeTextField}
                            error={requriedFields?.tax_address}
                        />
                    </Box>
                    <Box className="wform-component left-pane">
                        <Typography>
                            6. City, state, and ZIP Code
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    fullWidth
                                    id="tax_city"
                                    name="tax_city"
                                    label="City"
                                    value={normalizeValue(w9Detail?.tax_city)}
                                    onChange={onChangeTextField}
                                    error={requriedFields?.tax_city}
                                />
                            </Grid>
                            <Grid item xs={6} sm={2} className="city-details">
                                <TextField
                                    fullWidth
                                    id="tax_state"
                                    name="tax_state"
                                    label="State"
                                    value={normalizeValue(w9Detail?.tax_state)}
                                    onChange={onChangeTextField}
                                    error={requriedFields?.tax_state}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3} className="city-details">
                                <InputMask
                                    mask="99999"
                                    value={normalizeValue(w9Detail?.tax_zipcode)}
                                    maskChar="_"
                                    onChange={onChangeTextField}
                                    error={requriedFields?.tax_zipcode}
                                    >
                                    {() => <TextField 
                                                id="tax_zipcode" 
                                                name="tax_zipcode" 
                                                label="Zip Code" 
                                                error={requriedFields?.tax_zipcode}
                                            />
                                    }
                                </InputMask>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="wform-component left-pane">
                        <Typography>
                            7. List account number(s) here (optional)
                        </Typography>
                        <TextField
                            fullWidth
                            id="account_number"
                            name="account_number"
                            label="Account Numbers"
                            value={normalizeValue(w9Detail?.account_number)}
                            onChange={onChangeTextField}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} className="wform-component right-pane" sx={{padding: '24px'}}>
                    <Typography>
                        Requester’s name and address (optional)
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        id="tax_address2"
                        name="tax_address2"
                        value={normalizeValue(w9Detail?.tax_address2)}
                        onChange={onChangeTextField}
                    />
                </Grid>
                <Grid item xs={1} className='part-header'>
                    Part I
                </Grid>
                <Grid item xs={11} className='wform-component part-header-title'>
                    TaxPayer Identification Numer (TIN)
                </Grid>
                <Grid item xs={12} className="wform-component taxidnumber-container">
                    <Box className='taxidnumber-description'>
                        <Typography>
                            Enter your TIN in the appropriate box.
                            The TIN provided must match the name given on line 1 to avoid backup withholding.
                            For individuals, this is generally your social security number (SSN).
                            However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later.
                            For other entities, it is your employer identification number (EIN).
                        </Typography>
                    </Box>
                    <Box className='taxidnumber-content'>
                        <Typography>
                            SOCIAL SECURITY NUMBER
                            <Switch color="default" checked={w9Detail?.tax_type=="ein"} onChange={changeTaxType}/>
                            EMPLOYER IDENTIFICATION NUMBER
                        </Typography>
                        {w9Detail?.tax_type == "ssn" ? 
                            <InputMask
                                mask="999-99-9999"
                                value={normalizeValue(w9Detail?.tax_ssn)}
                                maskChar="_"
                                onChange={onChangeTextField}
                                >
                                {() => <TextField fullWidth
                                            id="tax_ssn" 
                                            name="tax_ssn" 
                                            error={requriedFields?.tax_ssn}
                                        />
                                }
                            </InputMask>
                            :
                            <InputMask
                                mask="99-9999999"
                                value={normalizeValue(w9Detail?.tax_ein)}
                                maskChar="_"
                                onChange={onChangeTextField}
                                >
                                {() => <TextField fullWidth
                                            id="tax_ein" 
                                            name="tax_ein" 
                                            error={requriedFields?.tax_ein}
                                        />
                                }
                            </InputMask>
                        }
                    </Box>
                </Grid>
                <Grid item xs={1} className='part-header'>
                    Part II
                </Grid>
                <Grid item xs={11} className='wform-component part-header-title'>
                    Certification
                </Grid>
                <Grid item xs={12} className="wform-component" sx={{padding: '16px 0'}}>
                    <Typography>Under penalties of perjury, I certify that:</Typography>
                    <Typography>
                        1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and
                    </Typography>
                    <Typography>
                        2. I am not subject to backup withholding because:
                            (a) I am exempt from backup withholding, or
                            (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or
                            (c) the IRS has notified me that I am no longer subject to backup withholding; and
                    </Typography>
                    <Typography>
                        3. I am a U.S. citizen or other U.S. person (defined below); and
                    </Typography>
                    <Typography>
                        4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.
                    </Typography>
                    <Typography>
                        Certification instructions. You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding
                        because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply.
                        For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA),
                        and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.
                    </Typography>
                </Grid>
                <Grid item xs={1} className='part-header'>
                    SIGN HERE
                </Grid>
                <Grid item xs={11} sx={{borderBottom: '1px solid black'}}>
                    <Grid container spacing={1} sx={{paddingTop: '6px', paddingBottom: 0}}>
                        <Grid item sm={2} className='sign-description'>
                            <Typography>Signature of U.S. Person</Typography>
                            <ArrowRightIcon/>
                        </Grid>
                        <Grid item sm={5}>
                            <TextField
                                fullWidth
                                id="tax_signature_usperson"
                                name="tax_signature_usperson"
                                className="signature"
                                value={normalizeValue(w9Detail?.tax_signature_usperson)}
                                onChange={onChangeTextField}
                                error={requriedFields?.tax_signature_usperson}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={1} className='sign-description'>
                            <Typography>Date</Typography>
                            <ArrowRightIcon/>
                        </Grid>
                        <Grid item sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    fullWidth
                                    value={w9Detail?.tax_signature_dts}
                                    onChange={(newValue) => onChangeSignDate(newValue)}
                                    inputFormat="MM/dd/yyyy"
                                    renderInput={(params) => <StyledTextField size="small" {...params}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{margin: '12px 0 24px'}}>
                <Grid item sm={6}>
                    <Button target="_blank" onClick={()=> handleW9Download(`vendor_w9_stamped_${vendor.userid}.pdf`)}>
                        DOWNLOAD W-9 <VerticalAlignBottomIcon/>
                    </Button>
                </Grid>
                {/* <Grid item sm={6} sx={{textAlign: 'right'}}>
                    <Button target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">
                        DOWNLOAD W-9 <VerticalAlignBottomIcon/>
                    </Button>
                </Grid> */}
            </Grid>
        </FormGroup>
    );
}