import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";

const CheckIcon = styled(DoneIcon)(css`
    margin-right: 10px;
    color: yellowgreen;
`);

const WelcomeContent = styled('div')(css`
    line-height: 2;
`);

const FirmLogoBox = styled('div')(css`
    text-align: center;
    margin: 26px;
    img {
        width: 184px
    }
`);

const CompletedSteps = styled('div')(css`
    margin-top: 24px;
    align-items: center;
    display: flex;
`);

const ReviewContent = styled('div')(css`
    line-height: 2;
    padding-left: 80px;
    font-size: 13px;
`);

const ElseContent = styled('div')(css`
    line-height: 2;
    margin-top: 24px;
    margin-bottom: 24px;
`);

const ContactContent = styled('div')(css`
    font-weight: bold;
    margin-bottom: 24px;
`);

const ButtonBox = styled(Box)(css`
    margin-top: 40px;
    margin-bottom: 120px;
    text-align: center;
`);

export function LandingPage ({ nextRegisterStep, firmDetails }) {
    const onClickStarted = () => {
        nextRegisterStep();
    }

    return (
        <Box>
            <Box className='validation-title'>
                WELCOME TO VOXTUR
            </Box>
            {firmDetails?.firm_logo &&
                <FirmLogoBox>
                    <img src={`data:image/png;base64,${firmDetails.firm_logo}`}/>
                </FirmLogoBox>
            }
            <Box className='step-container font-small'>
                <WelcomeContent>
                    Hello!
                </WelcomeContent>
                {firmDetails ?
                    <WelcomeContent>
                        {firmDetails.company_name} Firm has joined the Voxtur Attorney Network
                        and has invited you to complete the application process to open your account.
                    </WelcomeContent>
                    :
                    <WelcomeContent>
                        Thank you for your interest in joining the Voxtur Attorney Network.
                        We look forward to working with you. To begin the onboarding process,
                        please complete each of the steps below. 
                    </WelcomeContent>
                }
                <CompletedSteps>
                    <CheckIcon fontSize="large"/>
                    Complete the Voxtur Network Attorney Application.
                </CompletedSteps>
                <CompletedSteps>
                    <CheckIcon fontSize="large"/>
                    Upload Certificate(s) of Good Standing (please include one certificate for each jurisdiction in which you are licensed)
                </CompletedSteps>
                <CompletedSteps>
                    <CheckIcon fontSize="large"/>
                    Review and execute each of the following:
                </CompletedSteps>
                <ReviewContent>Voxtur Network Attorney Agreement</ReviewContent>
                <ReviewContent>Voxtur Social Media Policy</ReviewContent>
                <ElseContent>
                    Once you have completed the steps above and your application has been accepted, you will receive an email with additional information, including instructions regarding access to the Voxtur AOL platform and training materials.
                </ElseContent>
                <ContactContent>
                    If you have any questions regarding the onboarding process, please contact us at aol@vss.voxtur.com.
                </ContactContent>
                <ButtonBox>
                    <Button className='next-step' variant="contained" onClick={onClickStarted}>
                        LET’S GET STARTED
                    </Button>
                </ButtonBox>
            </Box>
        </Box>
    );
}