import { Text, View, StyleSheet } from '@react-pdf/renderer';

const SocialMediaGuidelinePDFRender = () => {
  return (
    <View style={styles.container}>
        <Text style={styles.title}>VOXTUR SOCIAL MEDIA POLICY</Text>
        <View style={styles.paragraph}>
          <Text>
            At Voxtur, we understand that social media can be a fun and rewarding way to share your life and opinions with family, friends, and co-workers around the world. However, use of social media also presents certain risks and carries with it certain responsibilities. To assist you in making responsible decisions about your use of social media, we have established these guidelines for appropriate use of social media.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text>
            This policy applies to all individuals who provide services for or on behalf of Voxtur, or one of its subsidiary companies in the United States and all individuals with access to the Voxtur AOL Platform.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Guidelines</Text>
        </View>
        <View style={styles.paragraph}>
          <Text>Social media includes all means of communicating or posting information or content of any sort on the Internet, including to your own or someone else’s web log or blog, journal or diary, personal web site, social networking or affinity web site, web bulletin board or a chat room, whether or not associated or affiliated with Voxtur, as well as any other form of electronic communication.
        </Text>
        </View>
        <View style={styles.paragraph}>
          <Text>he same principles and guidelines found in Voxtur policies apply to your activities online. Ultimately, you are solely responsible for what you post online. Before creating online content, consider some of the risks and rewards that are involved. Keep in mind that any of your conduct that adversely affects your job performance, the performance of fellow associates or otherwise adversely affects members, customers, suppliers, people who work on behalf of Voxtur or Voxtur’s legitimate business interests may result in disciplinary action up to and including termination and/or immediate removal from the Voxtur Attorney Network.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Know and follow the rules. </Text>
          <Text>Carefully read these guidelines and ensure your postings are consistent with these policies. Inappropriate postings that may include discriminatory remarks, harassment, and threats of violence or similar inappropriate or unlawful conduct will not be tolerated and may subject you to disciplinary action up to and including termination and/or immediate removal from the Voxtur Attorney Network.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Be respectful.</Text>
          <Text>Always be fair and courteous to fellow associates, customers, members, suppliers, or people who work on behalf of Voxtur. Also, keep in mind that you are more likely to resolve work-related complaints by reporting your concerns through appropriate management channels than by posting complaints to a social media outlet. Nevertheless, if you decide to post complaints or criticism, avoid using statements, photographs, video, or audio that reasonably could be viewed as malicious, obscene, threatening or intimidating, that disparage customers, members, associates, or suppliers, or that might constitute harassment or bullying. Examples of such conduct might include offensive posts meant to intentionally harm someone’s reputation or posts that could contribute to a hostile work environment on the basis of race, sex, disability, religion or any other status protected by law or company policy.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Be honest and accurate. </Text>
          <Text> Make sure you are always honest and accurate when posting information or news, and if you make a mistake, correct it quickly. Be open about any previous posts you have altered.  Remember that the Internet archives almost everything; therefore, even deleted postings can be searched. Never post any information or rumors that you know to be false about Voxtur, fellow associates, members, customers, suppliers, people working on behalf of Voxtur or competitors.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Post only appropriate and respectful content. Do not post confidential information.</Text>
          <Text>Maintain the confidentiality of Voxtur’s trade secrets and private or confidential information. Trades secrets may include information regarding the development of systems, processes, products, know-how and technology. Do not post confidential information, internal reports, policies, procedures, or other internal business-related confidential communications.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Respect financial disclosure laws.</Text>
          <Text>It is illegal to communicate or give a “tip” on inside information to others so that they may buy or sell stocks or securities.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Identify yourself appropriately. </Text>
          <Text> Do not create a link from your blog, website or other social networking site to a Voxtur website without identifying yourself as a Voxtur associate.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Express only your personal opinions.</Text>
          <Text>Never represent yourself as a spokesperson for Voxtur. If Voxtur is a subject of the content you are creating, be clear and open about the fact that you are an associate and make it clear that your views do not represent those of Voxtur, fellow associates, members, customers, suppliers or people working on behalf of Voxtur.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Retaliation is prohibited.</Text>
          <Text>Voxtur prohibits taking negative action against any associate for reporting a possible deviation from this policy or for cooperating in an investigation. Any associate who retaliates against another associate for reporting a possible deviation from this policy or for cooperating in an investigation will be subject to disciplinary action, up to and including termination and/or immediate removal from the Voxtur Attorney Network.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Media Inquiries.</Text>
          <Text>Do not speak to the media on Voxtur’s behalf. All media inquiries should be directed to Voxtur’s Chief Legal Officer at legal@voxtur.com.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.label}>Social Media Policy Acknowledgement</Text>
          <Text>I acknowledge that I have received and reviewed a copy of Voxtur’s Social Media Policy. I understand my obligations under this policy. I understand that I must comply with this policy and I will notify Voxtur’s legal department at legal@voxtur.com if I observe any violations of the foregoing policy. I also agree to contact Voxtur’s legal department should I become aware that anyone has violated, is violating, or intends to violate the Social Media Policy. If I am unsure whether a violation has taken place, I will contact Voxtur’s legal department to discuss whether the certain transaction, activity, or post constitutes as a violation.</Text>
        </View>
        <View style={styles.paragraph}>
          <Text>I understand that failure to adhere to this policy may result in discipline, up to and including the immediate termination of employment and/or immediate removal from the Voxtur Attorney Network.</Text>
        </View>
      </View> 
  )
}
export default SocialMediaGuidelinePDFRender;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: 14,
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  paragraph: {
    paddingVertical: 10,
  },
  label: {
    fontSize: 11,
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  li: {
    paddingHorizontal: 12,
    paddingVertical: 4
  },
  liText: {
    paddingLeft: 8
  },
  leftMargin: {
    marginLeft: 12,
  },
  leftMargin20: {
    marginLeft: 20,
  },
});