import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export function RegisterHeader( { branding, registerStep } ) {
    const LAST_STEP = 6
    return (
        <Box sx={{padding: '20px 30px'}}>
            <Grid container>
                <Grid item xs={12} sm={8}>
                    {branding?.logoPrimary &&
                        <Box
                            sx={{
                                flexGrow: 1,
                                backgroundPosition: 'left',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(/common/companyfiles.php?logo=1&filename=${branding.logoPrimary})`,
                                height: 54,
                            }}
                            />
                    }
                </Grid>
                {registerStep > 0 && registerStep <= LAST_STEP &&
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <BorderLinearProgress variant="determinate" value={20*registerStep}/>
                            </Box>
                            <Box sx={{ minWidth: 70 }}>
                                <Typography variant="body2" color="text.secondary">
                                    STEP {registerStep}/{LAST_STEP}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    );
}