import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { W9Document } from './W9Document';
import { Footer } from "./footer";

export function PaymentInformation ({ nextRegisterStep, vendor, w9Form }) {
    const [w9Details, setW9Details] = useState(null)
    const [requriedFields, setRequriedFields] = useState(null)

    const onClickBack = () => {
        nextRegisterStep(-1)
    }

    const onClickNext = async () => {
        var requried = {}
        if (!w9Details || !w9Details.tax_fullname) {
            requried.tax_fullname = true
        }
        if (!w9Details || !w9Details.tax_city) {
            requried.tax_city = true
        }
        if (!w9Details || !w9Details.tax_address) {
            requried.tax_address = true
        }
        if (!w9Details || !w9Details.tax_state) {
            requried.tax_state = true
        }
        if (!w9Details || !w9Details.tax_zipcode) {
            requried.tax_zipcode = true
        }
        if (w9Details && w9Details.tax_other_checkbox) {
            if (!w9Details.tax_other_descrip) requried.tax_other_descrip = true
        }
        if (w9Details && w9Details.tax_classification) {
            if(!w9Details.tax_llc_type) requried.tax_llc_type = true
        }
        if (!w9Details || (!w9Details.tax_ssn && !w9Details.tax_ein)) {
            requried.tax_ssn = true
            requried.tax_ein = true
        }
        if (!w9Details || !w9Details.tax_signature_usperson) {
            requried.tax_signature_usperson = true
        }

        if (Object.keys(requried).length === 0) {
            const result = await vendor.updateVendorProfile(w9Details)
            if (result.status == "success")
                nextRegisterStep(1, w9Details)
            else
                console.log(result.errors[0].detail)
        }
        setRequriedFields(requried)
    }

    return (
        <Box>
            <Box className='step-title'>
                Payment Information
            </Box>
            <Box className='wform-container'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{fontSize: '16px !important'}}>
                        <Typography className='section-label-with-description'>Payment preferences</Typography>
                        <Typography className='color-grey section-description'>
                            Payment Information To ensure accurate payments, 
                            please submit a W-9 for your firm. The same W-9 will be used for all work performed under this firm.
                        </Typography>
                    </Grid>
                </Grid>
                <W9Document updateW9Details={setW9Details} requriedFields={requriedFields} w9Form={w9Form} vendor={vendor}/>
                <Footer onClickBack={onClickBack} onClickNext={onClickNext}/>
            </Box>
        </Box>
    );
}