import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Footer } from "./footer";
import { isEmail, isPhone, normalizeValue } from 'utils'
import { cl } from '@twipped/utils';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";

const AttorneyContainer = styled(Box)(
    ({ theme }) => (css`
        color: ${theme.palette.primary.main};
        background-color: #EDF4FA;
        border-radius: 10px;
        padding: 36px;
        margin-bottom: 100px;
    `)
);

const SwitchContainer = styled(Box)(
    ({ theme }) => (css`
        text-align: center;
        margin-top: 36px;
    `)
);
  
export function InviteAttorneys ({nextRegisterStep, vendor, employees, showAlert}) {
    const [isInviteMode, SetInviteMode] = useState(false)
    const [attorneyList, setAttorneyList] = useState([])
    const [attorneyDetail, setAttorneyDetail] = useState(null)
    const [invalidFields, setInvalidFields] = useState(null)

    useEffect(() => {
        if (employees && employees.length > 0) {
            SetInviteMode(true)
            setAttorneyList(employees)
        }
    }, [])

    const deleteAttorney = async (uindex) => {
        const result = await vendor.deleteVendorEmployee(attorneyList[uindex].id)
        if (result.status == "success") {
            let newAttorneyList = attorneyList.slice()
            newAttorneyList.splice(uindex, 1)
            setAttorneyList(newAttorneyList)
        }
    }

    const addAttorney = async () => {
        var invalid = {}

        if (!attorneyDetail || !attorneyDetail.firstname) {
            invalid.firstname = true
        }
        if (!attorneyDetail || !attorneyDetail.lastname) {
            invalid.lastname = true
        }
        if (!attorneyDetail || !attorneyDetail.email || !isEmail(attorneyDetail.email)) {
            invalid.email = true
        }
        if (!attorneyDetail || !attorneyDetail.phone || !isPhone(attorneyDetail.phone)) {
            invalid.phone = true
        }

        if (Object.keys(invalid).length === 0) {
            const result = await vendor.addVendorEmployee({
                "firstname": attorneyDetail.firstname,
                "lastname": attorneyDetail.lastname,
                "email": attorneyDetail.email,
                "phone": attorneyDetail.phone,
                "vendor_type": 5
            })
            if (result.employeeid) {
                setAttorneyList([...attorneyList, {
                    id: result.employeeid,
                    ...attorneyDetail
                }])
                setAttorneyDetail(null)
            } else {
                if (result.errors && result.errors[0].detail && result.errors[0].detail.indexOf('Duplicate') != -1) {
                    showAlert(`${attorneyDetail.email} email account is already in use.  Please provide another email, or login to your existing account.`)
                    invalid.email = true
                }
                else
                    showAlert("Please try again")
            }
        }

        setInvalidFields(invalid)
    }

    const onClickBack = () => {
        nextRegisterStep(-1)
    }

    const onClickNext = () => {
        if (attorneyDetail && attorneyDetail.firstname && attorneyDetail.lastname && attorneyDetail.email && attorneyDetail.phone) {
            showAlert('You have to click "ADD ATTORNEY" button to save attorney')
            setInvalidFields({attorney: true})
            return
        }
        nextRegisterStep(1, attorneyList)
    }
    
    const onChangeTextField = (e) => {
        const { value, name } = e.target
    
        setAttorneyDetail(prevState => {
          return {
            ...prevState,
            [name]: value
          }
        })
    }

    const showAttorneyList = () => {
        return (
            <Box sx={{p: 2}}>
                <Grid container spacing={2} sx={{marginTop: '12px', borderBottom: '1px solid black'}}>
                    <Grid item sm={3}>FIRST NAME</Grid>
                    <Grid item sm={3}>LAST NAME</Grid>
                    <Grid item sm={3}>CELL PHONE</Grid>
                    <Grid item sm={3}>EMAIL</Grid>
                </Grid>
                {
                    attorneyList.length == 0 ?
                        <Typography variant="h4" sx={{textAlign: 'center', color: '#ff9800', marginTop: '12px'}}>
                            No attorneys
                        </Typography>
                    :
                    attorneyList.map((attorney, uindex) => {
                        return <Grid container spacing={2} className="table-row" key={"attorney"+uindex}>
                                <Grid item sm={3}>{attorney.firstname}</Grid>
                                <Grid item sm={3}>{attorney.lastname}</Grid>
                                <Grid item sm={3}>{attorney.phone}</Grid>
                                <Grid item sm={3} sx={{ position: 'relative' }}>
                                    {attorney.email}
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => deleteAttorney(uindex)}
                                        sx={{ color: 'red', position: 'absolute', right: 0, top: '8px' }}
                                        >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                    })
                }
            </Box>
        )
    }
    return (
        <Box>
            <Box className='step-title'>
                INVITE ATTORNEYS
            </Box>
            <Box className='step-container'>
                {isInviteMode ? 
                    <Box sx={{minHeight: '700px'}}>
                       <Box className='font-weight-bold'>
                           Invite Attorneys
                       </Box>
                       <Box>
                           Add additional affiliated attorneys who will be performing AOL and Title Services. 
                           Each attorney will be invited To complete their registration, including providing licensure and additional documentation.
                       </Box>
                       {showAttorneyList()}
                        <Grid container spacing={2} sx={{marginTop: '12px'}}>
                            <Grid item xs={3}>
                                <TextField
                                    id="firstname"
                                    name="firstname"
                                    label="First Name"
                                    value={normalizeValue(attorneyDetail?.firstname)}
                                    onChange={onChangeTextField}
                                    error={invalidFields?.firstname}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="lastname"
                                    name="lastname"
                                    label="Last Name"
                                    value={normalizeValue(attorneyDetail?.lastname)}
                                    onChange={onChangeTextField}
                                    error={invalidFields?.lastname}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={normalizeValue(attorneyDetail?.phone)}
                                    maskChar="_"
                                    onChange={onChangeTextField}
                                    >
                                    {() => <TextField 
                                                id="phone" 
                                                name="phone" 
                                                label="Mobile Phone"
                                                error={invalidFields?.phone}
                                            />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={normalizeValue(attorneyDetail?.email)}
                                    onChange={onChangeTextField}
                                    error={invalidFields?.email}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'right'}}>
                                <Button variant="text" onClick={addAttorney} className={invalidFields?.attorney && attorneyDetail && 'required'}>
                                    ADD ATTORNEY <LocalHospitalIcon sx={{ml: 2}}/>
                                </Button>
                            </Grid>
                        </Grid>
                   </Box>
                   :
                    <AttorneyContainer>
                        <Box className='font-weight-bold'>
                            Invite Attorneys
                        </Box>
                        <Box>
                            Add additional affiliated attorneys who will be performing AOL and Title Services. 
                            Each attorney will be invited To complete their registration, including providing licensure and additional documentation.
                        </Box>
                        <SwitchContainer>
                            <FormGroup sx={{display: 'block'}}>
                                <FormControlLabel control={<Switch onChange={() => SetInviteMode(true)}/>} label="ADD LICENSED ATTORNEYS TO THIS FIRM" />
                            </FormGroup>
                        </SwitchContainer>
                    </AttorneyContainer>
                }

                <Footer onClickBack={onClickBack} onClickNext={onClickNext}/>
            </Box>
        </Box>
    );
}