import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { Document } from 'core/models'
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { SubmissionDocument } from './SubmissionDocument';

const StyledDiv = styled('div')(css`
    line-height: 2;
`);

const ButtonBox = styled(Box)(css`
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
`);

export function WelcomToValidation ({branding, data}) {
    const onClickDownloadSubmission = async () => {
        const { error, result } = await Document.downloadSignature()

        if(error) return
    
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ApplicationCertification.png');
        document.body.appendChild(link);
        link.click();
    }

    return (
        <Box>
            <Box className='validation-title'>
                THANK YOU FOR APPLYING
            </Box>
            <Box className='step-container font-small text-center'>
                <StyledDiv className='font-weight-bold'>
                    Your Application Has Been Submitted for Review.
                </StyledDiv>
                <StyledDiv>
                    Once approved, you should receive a notification that you are eligible to receive orders.
                    Order announcements will be sent to the email and cell phone that you have indicated in the account.
                    The email and password you used to register with will allow you to accept orders and manage your account.
                </StyledDiv>
                
                <ButtonBox>
                    <PDFDownloadLink document={<SubmissionDocument branding={branding} data={data}/>} fileName="application_submission.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 
                            <Button className='next-step' variant="contained">
                                <DownloadIcon sx={{marginRight: '12px'}}/>
                                DOWNLOAD APPLICATION SUBMISSION
                            </Button>
                        }
                    </PDFDownloadLink>
                </ButtonBox>
            </Box>
        </Box>
    );
}