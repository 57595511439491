
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { RegisterHeader } from './header';
import useCompany from 'common/company';
import Vendor from 'common/models/vendor'
import { useSearchParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useAuthentication from 'common/authentication';

import { 
  InvitedPage,
  LandingPage,
  NetworkAttorneyApplication,
  NetworkAttorneyAgreement,
  SocialMediaPolicy,
  PaymentInformation,
  WelcomToValidation,
  InviteAttorneys,
  ApplicationCertification
} from './steps';

import './styles.scss'


export default function VendorRegisterPage () {
  const { branding } = useCompany();
  const [registerStep, setRegisterStep] = useState(0)
  const [stepSubPage, setStepSubPage] = useState(null)
  const [firmDetails, setFirmDetails] = useState(null)
  const [guidelines, setGuidelines] = useState(null);
  const [vendorProfile, setVendorProfile] = useState({});
  const [employees, setEmpolyees] = useState(null);
  const [w9Form, setW9Form] = useState(null);
  const [signature, setSignature] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [alertContent, setAlertConent] = useState('')
  const [severity, setSeverity] = useState(null)
  const [ searchParams, setSearchParams ] = useSearchParams();

  const { createSessionFromUrl } = useAuthentication();

  var vendor = (new Vendor);
  const LAST_STEP = 7;

  let decodedKey = null;
  const encodedKey = searchParams.get("key");
  if(encodedKey){
    let base64Str = atob(encodedKey);
    try{
      decodedKey = JSON.parse(base64Str);
    } catch(e){
      console.log(e);
    }
  }

  useEffect(async () => {
    if (encodedKey) {
      
        let userData = await createSessionFromUrl(encodedKey);

        let vendorPayload = await vendor.getVendorPublicProfile();

        let currentUser =  vendorPayload.vendor;
       
        if(currentUser) {
          setVendorProfile(currentUser)
          const result = await vendor.getFirmDetails(currentUser.parent_user, currentUser.userid);
          if (result.vendor) {
            setFirmDetails(result.vendor)
          } else {
            setFirmDetails({"error": true})
          }  
        }
    }
  }, [])

  const steps = () => {
    switch(registerStep) {
      case 0: return <LandingPage nextRegisterStep={nextRegisterStep} firmDetails={firmDetails}/>
      case 1: return <NetworkAttorneyApplication nextRegisterStep={nextRegisterStep} showAlert={showAlert} vendor={vendor} firmDetails={firmDetails} vendorProfile={vendorProfile}/>
      case 2: return <NetworkAttorneyAgreement nextRegisterStep={nextRegisterStep} guideline={guidelines?.general ?? {}}/>
      case 3: return <SocialMediaPolicy nextRegisterStep={nextRegisterStep} guideline={guidelines?.socialmedia ?? {}}/>
      case 4:
        if (decodedKey?.firm)
          return <ApplicationCertification nextRegisterStep={nextRegisterStep} setSignature={setSignature}/>
        else
          return <InviteAttorneys nextRegisterStep={nextRegisterStep} vendor={vendor} employees={employees} showAlert={showAlert}/>
      case 5: return <PaymentInformation nextRegisterStep={nextRegisterStep} vendor={vendor} w9Form={w9Form}/>
      case 6: return <ApplicationCertification nextRegisterStep={nextRegisterStep} setSignature={setSignature}/>
      case LAST_STEP: 
        const data = {...vendorProfile, "employees": employees, "w9form": w9Form, "signature": signature, guidelines }
        return <WelcomToValidation branding={branding} data={data} generalGuideline={guidelines?.general ?? {}} socialGuideline={guidelines.socialmedia ?? {}} nextRegisterStep={nextRegisterStep}/>

      default: return null
    }
  }


  const parseGuidelineArray = (guidelines) => {

    let guidelineObject = {};
    guidelines.forEach( g => {   
      if(!g.guideline_type || g.guideline_type == "") {
        guidelineObject['general'] = g;
        return;
      } 

      guidelineObject[g.guideline_type] = g;
    })

    return guidelineObject;
  }

  const nextRegisterStep = async (next=1, profile=null) => {
    if (profile) {
      if (registerStep == 4)
        setEmpolyees(profile)
      else if (registerStep == 5)
        setW9Form(profile)
      else {
        setVendorProfile(Object.assign({}, vendorProfile, profile))
        setW9Form({
          'tax_address': profile.compaddress1,
          'tax_city': profile.compcity,
          'tax_state': profile.compstate,
          'tax_zipcode': profile.compzipcode,
          'tax_type': 'ein'
        })
      }
    }
    
    let step = registerStep + next
    let guidelineObj = {}
    if (!guidelines && step == 2) {

      if(!vendorProfile.userid){
        vendor = (new Vendor);
        const result = await vendor.getVendorPublicProfile();

        guidelineObj = parseGuidelineArray(result?.vendor?.guidelines ?? []);
      } else {
        guidelineObj = parseGuidelineArray(vendorProfile?.guidelines ?? []);
      }

      setGuidelines(guidelineObj)
    }
    if (decodedKey?.firm && step > 4)
      step = LAST_STEP
    if (registerStep <= LAST_STEP)
      setRegisterStep(step)
  }
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const showAlert = (alert, info=null) => {
    setAlertConent(alert)
    setSeverity(info)
    setShowSnackbar(true);
  };
  return (
    <Container>
      {registerStep == 0 && decodedKey?.firm && !stepSubPage ? 
        <LandingPage nextRegisterStep={nextRegisterStep} firmDetails={firmDetails}/>
        :
        <Box sx={{ bgcolor: 'white'}}>
          <RegisterHeader branding={branding} registerStep={registerStep}/>
          <Box>{ steps() }</Box>
        </Box>
      }
      <Snackbar open={showSnackbar} autoHideDuration={10000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={severity ? severity : "error"} sx={{ width: '100%' }}>
          {alertContent}
        </Alert>
      </Snackbar>
    </Container>
  );
}

