import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import States from 'common/states';
import useAuthentication from 'common/authentication';
import InputMask from "react-input-mask";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import PasswordStrengthBar from 'react-password-strength-bar';
import CircularProgress from '@mui/material/CircularProgress';
import Vendor from 'common/models/vendor'
import { Licensing } from './Licensing';
import { format } from 'date-fns'
import { isEmail, isPhone, isZipCode, normalizeValue, formatCurrency } from 'utils'
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";

const StyledTextField = styled(TextField)(css`
    text-transform: inherit;
`);

const FormContainer = styled(Box)(css`
    display: flex;
    .left-pane {
        width: 50%;
        padding-right: 16px;
    }
    .right-pane {
        width: 50%;
        padding-left: 16px;
        text-align: center;
    }
`);

const FormBrandContainer = styled(Box)(css`
    height: 288px;
    border: 1px solid #cbcbcb;
    text-align: center;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1px;
    .logo-description {
        margin: 16px;
        font-size: 14px;
    }
    img {
        max-width: 300px;
        max-height: 250px;
    }
`);

const FirmDetailContainer = styled('div')(css`
    display: flex;
    img {
        width: 90px
    }
    div {
        margin-left: 32px;
    }
`);

const LicensureContainer = styled(Box)(
    ({ theme }) => (css`
        color: ${theme.palette.primary.main};
        background-color: #EDF4FA;
        border-radius: 10px;
        padding: 36px;
    `)
);

const SwitchContainer = styled(Box)(
    ({ theme }) => (css`
        text-align: center;
        margin-top: 36px;
    `)
);

const Input = styled('input')({
    display: 'none',
});

export function NetworkAttorneyApplication ({ nextRegisterStep, showAlert, vendor, firmDetails, vendorProfile, employee }) {

    const [appDetail, setAppDetail] = useState(null)
    const [requriedFields, setRequriedFields] = useState(null)
    const [firmLogoFile, setFirmLogoFile] = useState(false)
    const [isLicensureMode, setLicensureMode] = useState(false)
    const [licenseDetail, setLicenseDetail] = useState(null)
    const [licensingList, setLicensingList] = useState([])
    const [vendorTypes, setVendorTypes] = useState([])
    const [passwordScore, setPasswordScore] = useState(0)
    const [loading, setLoading] = useState(false)
    const {login} = useAuthentication();
    const isLogined = Object.keys(vendorProfile).length != 0
    useEffect(async () => {
        //Scroll to top of registration form
        document.documentElement.scrollTo({top:0,left:0,behavior:'instant'});
        
        const result = await vendor.getVendorType();
        let details = {};
        setVendorTypes(result.vendors)
        if (result.vendors) {
            for (let vendor of result.vendors) {
                if (vendor.description == 'Attorney') {
                    details = {
                        vendor_type: vendor.id,
                        vendor: 'Attorney'
                    }
                    break
                }
            }
        }
        if (isLogined) {
            details = {
                ...vendorProfile,
                vendor_type: vendorProfile.vendor_typeid,
                confirm_password: vendorProfile.password
            }
            if (vendorProfile.certificates && vendorProfile.certificates.length > 0) {
                setLicensureMode(true)
                setLicensingList(vendorProfile.certificates)
            }
        } else if (firmDetails) {
            details['firstname'] = firmDetails.employee.firstname
            details['lastname'] = firmDetails.employee.lastname
            details['email'] = firmDetails.employee.email
        }
        setAppDetail(details)
    }, [])

    const onChangeTextField = (e) => {
        const { value, name } = e.target
    
        setAppDetail(prevState => {
          return {
            ...prevState,
            [name]: value
          }
        })
    }

    const onChangeCurrencyField = (e) => {
        const { value, name } = e.target
        if (isNaN(value.slice(-1))) return;
        setAppDetail(prevState => {
          return {
            ...prevState,
            [name]: formatCurrency(value.replaceAll(',', ''))
          }
        })
    }

    const onChangeCheckBox = (e) => {
        const { checked, name } = e.target
        if (name == 'contact_pref_email' || name == 'contact_pref_text')
            setAppDetail(prevState => {
                return {
                ...prevState,
                [name]: checked ? "Y" : "N"
                }
            })
        else
            setAppDetail(prevState => {
                return {
                ...prevState,
                [name]: checked
                }
            })
    };

    const onAutoCompleteChange = (name,newvalue) => {

        setAppDetail(prevState => {
            return {
            ...prevState,
            [name]: (newvalue && newvalue.id ? newvalue.id : null)
            }
        });

    };

    const onChangeSelect = (e) => {
        const { value, name } = e.target
        // if (name == "vendor_type")
        //     for (let vendor of vendorTypes) {
        //         if (vendor.id == value) {
        //             setAppDetail(prevState => {
        //                 return {
        //                 ...prevState,
        //                 [name]: value,
        //                 "vendor": vendor.description
        //                 }
        //             })
        //         }
        //     }
        // else
        
            setAppDetail(prevState => {
                return {
                ...prevState,
                [name]: value
                }
            })
    };

    const onChangeFile = (e) => {
        if (!e.target.files) {
            return;
        }
        const { name } = e.target
        setAppDetail(prevState => {
            return {
              ...prevState,
              [name]: e.target.files[0]
            }
        })
        if (name == "firm_logo") {
            setFirmLogoFile(URL.createObjectURL(e.target.files[0]))
        }
    }

    const onChangeRadio = (e, name) => {
        const { value } = e.target
        setAppDetail(prevState => {
            return {
              ...prevState,
              [name]: value
            }
        })
    };

    const getFormatDate = (date=null) => {
        if (!date)
            date = new Date()

        return format(date, 'MM/dd/yyyy')
    }

    const onChangeDate = (value, name) => {
        let newAppDetail = {...appDetail}
        if (value) {
            var date = new Date(value)
            if(date < new Date()) return
            newAppDetail[name] = getFormatDate(date)
        } else {
            newAppDetail[name] = ""
        }

        setAppDetail(newAppDetail)
    }

    const switchLicensureMode = () => {
        setLicensureMode(true)
        showAlert('You have to click "ADD CERTIFICATE OF GOOD STANDING" button to save licensure', 'info')
    }
    const onClickNext = async () => {
        // nextRegisterStep(1, {...appDetail, "certificates": licensingList})
        // return
        var requried = {}

        // Law Firm Information
        if (!firmDetails) {
            if (!appDetail || !appDetail.compname) {
                requried.compname = true
            }
            if (!appDetail || !appDetail.compaddress1) {
                requried.compaddress1 = true
            }
            if (!appDetail || !appDetail.compcity) {
                requried.compcity = true
            }
            if (!appDetail || !appDetail.compzipcode || !isZipCode(appDetail.compzipcode)) {
                requried.compzipcode = true
            }
            if (!appDetail || !appDetail.compstate) {
                requried.compstate = true
            }
        }
        
        // Contact Information
        if (!appDetail || !appDetail.firstname) {
            requried.firstname = true
        }
        if (!appDetail || !appDetail.lastname) {
            requried.lastname = true
        }
        if (!appDetail || !appDetail.job_title) {
            requried.job_title = true
        }
        if (!appDetail || !appDetail.vendor_type) {
            requried.vendor_type = true
        }
        if (!appDetail || !appDetail.phone || !isPhone(appDetail.phone)) {
            requried.phone = true
        }
        if (!appDetail || !appDetail.mobile || !isPhone(appDetail.mobile)) {
            requried.mobile = true
        }
        if (!appDetail || !appDetail.email || !appDetail.confirm_email || appDetail.email != appDetail.confirm_email || !isEmail(appDetail.email)) {
            requried.email = true
            requried.confirm_email = true
        }
        if (!appDetail || !appDetail.password || !appDetail.confirm_password || appDetail.password != appDetail.confirm_password) {
            requried.password = true
            requried.confirm_password = true
        }
        if (passwordScore < 2) requried.password = true

        // Malpractice Insurance Coverage
        if (!firmDetails) {
            if (!appDetail || !appDetail.eo_expires) {
                requried.eo_expires = true
            }
            if (!appDetail || !appDetail.eo_ins_company) {
                requried.eo_ins_company = true
            }
            if (!appDetail || !appDetail.eo_amount_each) {
                requried.eo_amount_each = true
            }
            if (!appDetail || !appDetail.eo_amount_agg) {
                requried.eo_amount_agg = true
            }
            if (!appDetail || !appDetail.malpractice_document) {
                requried.malpractice_document = true
            }
        }

        // Experience & History
        if (!appDetail || !appDetail.experience_years) {
            requried.experience_years = true
        }
        if(['Y','N'].indexOf(appDetail?.malpractice_claim) === -1 )
        {
            requried.malpractice_claim = true;
        }
        if(['Y','N'].indexOf(appDetail?.criminal_action) === -1 )
        {
            requried.criminal_action = true;
        }
        if(['Y','N'].indexOf(appDetail?.perceived_conflict) === -1 )
        {
            requried.perceived_conflict = true;
        }
        if (appDetail?.malpractice_claim == "Y" && !appDetail.malpractice_claim_explain) {
            requried.malpractice_claim_explain = true
        }
        if (appDetail?.criminal_action == "Y" && !appDetail.criminal_action_explain) {
            requried.criminal_action_explain = true
        }
        if (appDetail?.perceived_conflict == "Y" && !appDetail.perceived_conflict_explain) {
            requried.perceived_conflict_explain = true
        }

        if (licenseDetail && licenseDetail.license_state && licenseDetail.license_number && licenseDetail.license_status) {
            requried.license = true
            showAlert('You have to click "ADD CERTIFICATE OF GOOD STANDING" button to save licensure')
        }

        if (Object.keys(requried).length === 0) {
            let profile = {
                ...appDetail,
                "certificates": licensingList
            }
            if (!profile.malpractice_claim) profile.malpractice_claim = ""
            if (!profile.criminal_action) profile.criminal_action = ""
            if (!profile.perceived_conflict) profile.perceived_conflict = ""

            setLoading(true)
            let result;
            if (!isLogined)
                result = await vendor.addVendorProfile(profile)
            else
                result = await vendor.updateVendorProfile({
                    ...profile,
                    vendor_typeid: profile.vendor_type,
                    vendor_types: profile.vendor_type,
                    guidelines: []
                })

            if (result.status == "success") {
                if (!isLogined) {
                    const { success , userType } = await login(profile.email, profile.password, true);
                    if (!success) {
                        showAlert("Failed register, try again")
                        setLoading(false)
                        setRequriedFields(requried)
                        return
                    }
                }
                // upload documents
                try {
                    const api = (new Vendor);
                    if (profile?.malpractice_document)
                        await api.uploadVendorDocument(profile.malpractice_document, "insurance",null)
                    for (let license of profile.certificates) {
                        if (license.licensePDF)
                            await api.uploadVendorDocument(license.licensePDF, "license", license.license_state )
                    }
                    if (profile.firm_logo)
                        await api.saveVendorFirmLogo(profile.firm_logo)
                    nextRegisterStep(1, profile)
                    return
                } catch (error) {
                    showAlert(error)
                }
            } else {
                if (result.errors && result.errors[0].detail && result.errors[0].detail.indexOf('Duplicate') != -1) {
                    showAlert(`${profile.email} email account is already in use.  Please provide another email, or login to your existing account.`)
                    requried.email = true
                }
                else
                    showAlert("Please try again")
            }
        }
        setLoading(false)
        setRequriedFields(requried)
    }

    return (
        <Box>
            <Box className='step-title'>
                NETWORK ATTORNEY APPLICATION
            </Box>
            <FormGroup className='step-container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className='section-label'>{firmDetails && "Your "}Law Firm Information</Typography>
                    </Grid>
                </Grid>
                {!firmDetails ?
                <FormContainer>
                    <Box className='left-pane'>
                        <StyledTextField
                            fullWidth
                            id="compname"
                            name="compname"
                            label="Firm Name"
                            value={normalizeValue(appDetail?.compname)}
                            onChange={onChangeTextField}
                            error={requriedFields?.compname}
                            sx={{mb: 2}}
                        />
                        <StyledTextField
                            fullWidth
                            id="compaddress1"
                            name="compaddress1"
                            label="Street Address"
                            value={normalizeValue(appDetail?.compaddress1)}
                            onChange={onChangeTextField}
                            error={requriedFields?.compaddress1}
                            sx={{mb: 2}}
                        />
                        <StyledTextField
                            fullWidth
                            id="compaddress2"
                            name="compaddress2"
                            label="Street Address 2/Suite"
                            value={normalizeValue(appDetail?.compaddress2)}
                            error={requriedFields?.compaddress2}
                            onChange={onChangeTextField}
                            sx={{mb: 2}}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={8} sm={5}>
                                <StyledTextField
                                    fullWidth
                                    id="compcity"
                                    name="compcity"
                                    label="City"
                                    value={normalizeValue(appDetail?.compcity)}
                                    onChange={onChangeTextField}
                                    error={requriedFields?.compcity}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth error={requriedFields?.compstate}>
                                    <Autocomplete
                                        autoComplete={true}
                                        autoSelect={true}
                                        id="compstate"
                                        name="compstate"
                                        options={ Object.keys(States).map( (key) => {
                                            return {label : key , id : key, statename:States[key]}
                                        }) }
                                        renderInput={(params) => <TextField {...params} name="compstate" label="State" error={requriedFields?.compstate} />}
                                        value={ ( appDetail && appDetail.compstate && appDetail.compstate != "" ? appDetail.compstate : null ) }
                                        onChange={(e,newvalue) => {
                                            onAutoCompleteChange("compstate",newvalue);
                                        }}
                                        isOptionEqualToValue={(option,value) => {
                                            return (option && option.id === value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <InputMask
                                    mask="99999"
                                    value={normalizeValue(appDetail?.compzipcode)}
                                    maskChar="_"
                                    onChange={onChangeTextField}
                                    >
                                    {() => <StyledTextField 
                                                fullWidth
                                                id="compzipcode" 
                                                name="compzipcode" 
                                                label="Zip Code" 
                                                error={requriedFields?.compzipcode}
                                            />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item sm={8}>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={normalizeValue(appDetail?.office_phone)}
                                    maskChar="_"
                                    onChange={onChangeTextField}
                                    >
                                    {() => <StyledTextField 
                                                fullWidth
                                                id="office_phone"
                                                name="office_phone" 
                                                label="Office Phone" 
                                                error={requriedFields?.office_phone}
                                            />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item sm={4}>
                                <InputMask
                                    mask="999"
                                    value={normalizeValue(appDetail?.office_phoneext)}
                                    maskChar=" "
                                    onChange={onChangeTextField}
                                    >
                                    {() => <StyledTextField 
                                                id="office_phoneext"
                                                name="office_phoneext" 
                                                label="Ext" 
                                                error={requriedFields?.office_phoneext}
                                            />
                                    }
                                </InputMask>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='right-pane'>
                        <FormBrandContainer>
                            {firmLogoFile ? 
                                <img src={firmLogoFile}/>
                                :
                                <Box className='logo-description'> Upload a firm logo file AOL branding. </Box>
                            }
                        </FormBrandContainer>
                        <Button fullWidth component="label" variant="contained" sx={{height: '54px'}}>
                            <VerticalAlignTopIcon sx={{mr: 1}}/>Upload Logo
                            <Input name="firm_logo" type="file" accept="image/png, image/jpeg" onChange={onChangeFile}/>
                        </Button>
                    </Box>
                </FormContainer>
                :
                <FirmDetailContainer>
                    <img src={`data:image/png;base64,${firmDetails.firm_logo}`}/>
                    <Box>
                        <Typography>{firmDetails.company_name} Law Firm</Typography>
                        <Typography>{firmDetails.company_address1}</Typography>
                        <Typography>{firmDetails.company_city}, {firmDetails.company_state} {firmDetails.company_zipcode}</Typography>
                    </Box>
                </FirmDetailContainer>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{mt: 3}}>
                        <Typography className='section-label' sx={{marginBottom: "0 !important"}}>Personal Information</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            fullWidth
                            id="firstname"
                            name="firstname"
                            label="First Name"
                            value={normalizeValue(appDetail?.firstname)}
                            onChange={onChangeTextField}
                            error={requriedFields?.firstname}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            fullWidth
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            value={normalizeValue(appDetail?.lastname)}
                            onChange={onChangeTextField}
                            error={requriedFields?.lastname}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            fullWidth
                            id="job_title"
                            name="job_title"
                            label="Title"
                            value={normalizeValue(appDetail?.job_title)}
                            onChange={onChangeTextField}
                            error={requriedFields?.job_title}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={requriedFields?.vendor_type}>
                            <InputLabel id="vendor-type">Select Vendor Type</InputLabel>
                            <Select
                                id="vendor_type"
                                name="vendor_type"
                                labelId="vendor-type" 
                                label="Select Vendor Type"
                                value={normalizeValue(appDetail?.vendor_type)}
                                onChange={onChangeSelect}
                            >
                            {vendorTypes.map((type) => {
                                return (
                                    <MenuItem value={type.id} key={type.id}>
                                        {type.description}
                                    </MenuItem>
                                );
                            })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            value={normalizeValue(appDetail?.email)}
                            onChange={onChangeTextField}
                            error={requriedFields?.email}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputMask
                            mask="(999) 999-9999"
                            value={normalizeValue(appDetail?.phone)}
                            maskChar="_"
                            onChange={onChangeTextField}
                            >
                            {() => <StyledTextField 
                                        fullWidth
                                        id="phone" 
                                        name="phone" 
                                        label="Work Phone" 
                                        error={requriedFields?.phone}
                                    />
                            }
                        </InputMask>
                    </Grid>
                    <Grid item xs={2}>
                        <InputMask
                            mask="999"
                            value={normalizeValue(appDetail?.phoneext)}
                            maskChar=" "
                            onChange={onChangeTextField}
                            >
                            {() => <StyledTextField 
                                        fullWidth
                                        id="phoneext" 
                                        name="phoneext" 
                                        label="Ext" 
                                        error={requriedFields?.phoneext}
                                    />
                            }
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            fullWidth
                            id="confirm_email"
                            name="confirm_email"
                            label="Confirm Email"
                            value={normalizeValue(appDetail?.confirm_email)}
                            onChange={onChangeTextField}
                            error={requriedFields?.confirm_email}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputMask
                            mask="(999) 999-9999"
                            value={normalizeValue(appDetail?.mobile)}
                            maskChar="_"
                            onChange={onChangeTextField}
                            >
                            {() => <StyledTextField 
                                        fullWidth
                                        id="mobile" 
                                        name="mobile" 
                                        label="Cell Phone"
                                        error={requriedFields?.mobile}
                                    />
                            }
                        </InputMask>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup aria-label="position" row>
                            <Typography sx={{marginTop: '8px', marginRight: '42px'}}>
                                Contact Preferences:
                            </Typography>
                            <FormControlLabel
                                id="contact_pref_email"
                                name="contact_pref_email"
                                control={<Checkbox />}
                                checked={appDetail?.contact_pref_email == "Y"}
                                onChange={onChangeCheckBox}
                                label="Email"
                            />
                            <FormControlLabel
                                id="contact_pref_text"
                                name="contact_pref_text"
                                control={<Checkbox />}
                                checked={appDetail?.contact_pref_text == "Y"}
                                onChange={onChangeCheckBox}
                                label="Text Message"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        {isLicensureMode ? 
                            <Licensing
                                licensingList={licensingList}
                                setLicensingList={setLicensingList}
                                licenseDetail={licenseDetail}
                                setLicenseDetail={setLicenseDetail}
                                license={requriedFields?.license}
                            />
                            :
                            <LicensureContainer>
                                <Box className='font-weight-bold'>
                                    Enable Order Assignments
                                </Box>
                                <Box>
                                    Please enable this feature if you wish to be eligible for order assignments.
                                    You will be prompted to provide the licensure information for each state you wish to receive assignments.
                                    There is an opportunity later in this registration process to invite other attorneys from your firm to complete assignments.
                                </Box>
                                <SwitchContainer>
                                    <FormGroup sx={{display: 'block'}}>
                                        <FormControlLabel control={<Switch onChange={switchLicensureMode}/>} label="Yes, make me eligible to accept orders" />
                                    </FormGroup>
                                </SwitchContainer>
                            </LicensureContainer>
                        }
                    </Grid>
                    {!firmDetails && <>
                    <Grid item xs={12}>
                        <Typography className='section-label-with-description'>Malpractice Insurance Coverage</Typography>
                        <Typography className='color-grey section-description'>
                            Please add information about your malpractice insurance provider and coverage. 
                            Please upload a copy of the policy and/or declarations page.
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <StyledTextField
                            fullWidth
                            id="eo_ins_company"
                            name="eo_ins_company"
                            label="Provider"
                            value={normalizeValue(appDetail?.eo_ins_company)}
                            onChange={onChangeTextField}
                            error={requriedFields?.eo_ins_company}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Expiration"
                                value={ (appDetail && appDetail.eo_expires && appDetail.eo_expires != "" ? appDetail.eo_expires : null ) }
                                onChange={(newValue) => onChangeDate(newValue, 'eo_expires')}
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params) => <TextField {...params} error={requriedFields?.eo_expires}/>}
                                disablePast
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="eo_amount_each" 
                            name="eo_amount_each" 
                            label="Amount (each)"
                            value={normalizeValue(appDetail?.eo_amount_each)}
                            onChange={onChangeCurrencyField}
                            error={requriedFields?.eo_amount_each}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            id="eo_amount_agg"
                            name="eo_amount_agg"
                            label="Amount (aggregate)"
                            value={normalizeValue(appDetail?.eo_amount_agg)}
                            onChange={onChangeCurrencyField}
                            error={requriedFields?.eo_amount_agg}
                        />
                    </Grid> 
                    <Grid item xs={3}>
                        {/* <Button fullWidth component="label" variant="contained" sx={{height: '54px', color: 'white'}} color={appDetail?.malpractice_document ? "selected" : "primary"}> */}
                        <Button fullWidth component="label" variant="contained" sx={{height: '54px', color: 'white'}} color={requriedFields?.malpractice_document && "error"}>
                            <VerticalAlignTopIcon sx={{mr: 1}}/>Select PDF
                            <Input name="malpractice_document" type="file" accept="application/pdf" onChange={onChangeFile}/>
                        </Button>
                        {appDetail?.malpractice_document && appDetail?.malpractice_document.name}
                    </Grid>
                    </>}
                    <Grid item xs={12}>
                        <Typography className='section-label' sx={{marginBottom: "0 !important"}}>Experience & History</Typography>
                    </Grid>
                    <Grid item xs={9} sx={{mt: 2}}>
                        Years of Experience Practicing in Real Estate or Related Field of Law:
                    </Grid>
                    <Grid item xs={3}>
                        <InputMask
                            mask="999"
                            value={normalizeValue(appDetail?.experience_years)}
                            maskChar=""
                            onChange={onChangeTextField}
                            >
                            {() => <TextField 
                                        id="experience_years" 
                                        name="experience_years" 
                                        error={requriedFields?.experience_years}
                                    />
                            }
                        </InputMask>
                    </Grid>
                    <Grid item xs={9}>
                        Are you aware of any fact, circumstance, incident, or situation that may result in a malpractice claim being made against you?
                    </Grid>
                    <Grid item xs={3}>
                        <RadioGroup row>
                            <FormControlLabel 
                                value="Y" 
                                control={
                                    requriedFields?.malpractice_claim ? (
                                        <Radio id="malpractice_claim" name="malpractice_claim" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Radio id="malpractice_claim" name="malpractice_claim"/>
                                    )
                                }
                                label="Yes" 
                                checked={appDetail?.malpractice_claim == "Y"}
                                onChange={(e) => onChangeRadio(e, 'malpractice_claim')}
                            />
                            <FormControlLabel 
                                value="N"
                                control={
                                    requriedFields?.malpractice_claim ? (
                                        <Radio id="malpractice_claim" name="malpractice_claim" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Radio id="malpractice_claim" name="malpractice_claim"/>
                                    )
                                }
                                label="No" 
                                checked={appDetail?.malpractice_claim == "N"}
                                onChange={(e) => onChangeRadio(e, 'malpractice_claim')}
                                sx={{marginLeft: '16px'}}
                            />
                        </RadioGroup>
                    </Grid>
                    {appDetail?.malpractice_claim == "Y" &&
                        <Grid item xs={12}>
                            <TextField
                                id="malpractice_claim_explain" 
                                name="malpractice_claim_explain" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Please explain"
                                variant="outlined"
                                value={normalizeValue(appDetail?.malpractice_claim_explain)}
                                onChange={onChangeTextField}
                                error={requriedFields?.malpractice_claim_explain}
                                />
                        </Grid>
                    }
                    <Grid item xs={9}>
                        Have you ever been the subject of a reprimand, disciplinary action, or criminal action by any federal, state, or local authority, professional association, or state licensing board?
                    </Grid>
                    <Grid item xs={3}>
                        <RadioGroup row>
                            <FormControlLabel 
                                value="Y" 
                                control={
                                    requriedFields?.criminal_action ? (
                                        <Radio id="criminal_action" name="criminal_action" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Radio id="criminal_action" name="criminal_action"/>
                                    )
                                }
                                label="Yes" 
                                checked={appDetail?.criminal_action == "Y"}
                                onChange={(e) => onChangeRadio(e, 'criminal_action')}
                            />
                            <FormControlLabel 
                                value="N" 
                                control={
                                    requriedFields?.criminal_action ? (
                                        <Radio id="criminal_action" name="criminal_action" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Radio id="criminal_action" name="criminal_action"/>
                                    )
                                }
                                label="No" 
                                checked={appDetail?.criminal_action == "N"}
                                onChange={(e) => onChangeRadio(e, 'criminal_action')}
                                sx={{marginLeft: '16px'}}
                            />
                        </RadioGroup>
                    </Grid>
                    {appDetail?.criminal_action == "Y" &&
                        <Grid item xs={12}>
                            <TextField
                                id="criminal_action_explain" 
                                name="criminal_action_explain" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Please explain"
                                variant="outlined"
                                value={normalizeValue(appDetail?.criminal_action_explain)}
                                onChange={onChangeTextField}
                                error={requriedFields?.criminal_action_explain}
                                />
                        </Grid>
                    }
                    <Grid item xs={9}>
                        Are you aware of any actual, potential, or perceived conflict of interest that may exist as a result of your providing services to Voxtur?
                    </Grid>
                    <Grid item xs={3}>
                        <RadioGroup row>
                            <FormControlLabel 
                                value="Y" 
                                control={
                                    requriedFields?.perceived_conflict ? (
                                        <Radio id="perceived_conflict" name="perceived_conflict" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Radio id="perceived_conflict" name="perceived_conflict"/>
                                    )
                                }
                                label="Yes" 
                                checked={appDetail?.perceived_conflict == "Y"}
                                onChange={(e) => onChangeRadio(e, 'perceived_conflict')}
                            />
                            <FormControlLabel 
                                value="N" 
                                control={
                                    requriedFields?.perceived_conflict ? (
                                        <Radio id="perceived_conflict" name="perceived_conflict" sx={{color: 'red !important', '&.Mui-checked': {color: 'red !important'}}}/>
                                    ) : (
                                        <Radio id="perceived_conflict" name="perceived_conflict"/>
                                    )
                                }
                                label="No" 
                                checked={appDetail?.perceived_conflict == "N"}
                                onChange={(e) => onChangeRadio(e, 'perceived_conflict')}
                                sx={{marginLeft: '16px'}}
                            />
                        </RadioGroup>
                    </Grid>
                    {appDetail?.perceived_conflict == "Y" &&
                        <Grid item xs={12}>
                            <TextField
                                id="perceived_conflict_explain" 
                                name="perceived_conflict_explain" 
                                fullWidth
                                multiline
                                rows={4}
                                label="Please explain"
                                variant="outlined"
                                value={normalizeValue(appDetail?.perceived_conflict_explain)}
                                onChange={onChangeTextField}
                                error={requriedFields?.perceived_conflict_explain}
                                />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography className='section-label-with-description'>Password</Typography>
                        <Typography className='color-grey section-description'>Avoid passwords that are easy to guess or used with other websites</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={normalizeValue(appDetail?.password)}
                            onChange={onChangeTextField}
                            error={requriedFields?.password}
                            inputProps={{
                                autoComplete: 'new-password',
                                form: {
                                  autocomplete: 'off',
                                },
                            }}
                        />
                        <PasswordStrengthBar
                            minLength={8}
                            password={appDetail?.password}
                            onChangeScore={(score, feedback) => setPasswordScore(score)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            fullWidth
                            id="confirm_password"
                            name="confirm_password"
                            label="Confirm Password"
                            type="password"
                            value={normalizeValue(appDetail?.confirm_password)}
                            onChange={onChangeTextField}
                            error={requriedFields?.password}
                            inputProps={{
                                autoComplete: 'new-password',
                                form: {
                                  autocomplete: 'off',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider variant="middle" sx={{margin: '30px 0'}}/>
                <Box className='text-center'>
                    <Collapse in={ requriedFields && Object.keys(requriedFields).length > 0 }>
                        <Alert severity="error">
                            <AlertTitle sx={{textAlign:'left'}}>Error</AlertTitle>
                            Please complete all highlighted required fields
                        </Alert>
                    </Collapse>
                    <Button className='next-step' variant="contained" onClick={onClickNext}>
                        Next
                        {loading && <CircularProgress size={24} sx={{position: 'absolute', right: '12px', color: 'white'}}/>}
                    </Button>
                </Box>
            </FormGroup>
        </Box>
    );
}